import { getCurrentInstance } from 'vue'

import { useNotification } from '@/composables/notification'

import type { Snackbar } from '@/composables/notification'

export interface CopyOptions {
  text: string
  success?: string
  error?: string
  element?: Element
  snackbarType?: keyof Snackbar
}

interface UseClipboardReturnValue {
  copyTextToClipboard (options: CopyOptions): void
}

export function useClipboard (): UseClipboardReturnValue {
  const { snackbar } = useNotification()
  const vm = getCurrentInstance()

  function copyTextToClipboard ({ text, success = 'Copied to clipboard.', error = 'Copy failed.', element, snackbarType = 'success' }: CopyOptions): void {
    const textArea = document.createElement('textarea')
    textArea.value = text

    textArea.setAttribute('readonly', '')
    textArea.style.position = 'fixed'
    textArea.style.pointerEvents = 'none'
    textArea.style.zIndex = '-9999'
    textArea.style.opacity = '0'
    textArea.style.top = '0'
    textArea.style.left = '0'

    try {
      /* istanbul ignore if: cannot append to $el in test env -- @preserve */
      if (!element) {
        element = vm?.proxy!.$el
        if (!element) throw new Error('Copy command failed')
      }

      element.appendChild(textArea)

      textArea.select()

      if (document.execCommand('copy')) {
        snackbar[snackbarType](success)
      } else {
        throw new Error('Copy command failed')
      }
    } catch (err) {
      snackbar.error(error)
    }

    element?.removeChild(textArea)
  }

  return {
    copyTextToClipboard,
  }
}
