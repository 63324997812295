import { defineStore } from 'pinia'

import { useAppContextStore } from '@/stores/appContext'
import { useAuthenticationStore } from '@/stores/authentication'
import { useFormEditPremiumStore } from '@/stores/form/editPremium'

import Payload from '@/lib/Payload'
import { handleChunkLoadError, hotReloadStore } from '@/utils/build'

import type { FormCoverage } from '@policyfly/schema/types/shared/formSchema'
import type { ProgramSlug } from 'types/program'
import type { LegacyQuoteReviewSchema, QuoteSchema } from 'types/schema'
import type { QuoteReviewSchema } from 'types/schema/quotereview'

export const useFormQuoteStore = defineStore({
  id: 'formQuote',

  state: () => ({
    sections: [] as QuoteSchema<FormCoverage>['sections'],
    sectionsSlug: '' as ProgramSlug | '',
    reviewData: {} as LegacyQuoteReviewSchema | QuoteReviewSchema,
    reviewSlug: '' as ProgramSlug | '',
    multiQuoteLoader: null as QuoteSchema['multiQuoteLoader'] | null,
    overlay: false,
  }),

  getters: {
    activeQuotePayload (): Payload | null {
      const appContextStore = useAppContextStore()
      const formEditPremiumStore = useFormEditPremiumStore()
      const quote = formEditPremiumStore.derivedDataQuotes[0] ||
        (appContextStore.loadedApplicationData.selectedQuote) || null
      if (!quote || !quote.responses) return null
      return Array.isArray(quote.responses)
        ? Payload.fromResponses(quote.responses)
        : Payload.fromResponseBlob(quote.responses)
    },
  },

  actions: {
    async loadSections (): Promise<QuoteSchema<FormCoverage>['sections'] | undefined> {
      const authenticationStore = useAuthenticationStore()
      const slug = authenticationStore.slug
      if (this.sectionsSlug === slug) return Promise.resolve(this.sections as QuoteSchema<FormCoverage>['sections'])
      this.sections = []
      this.multiQuoteLoader = null
      this.sectionsSlug = ''
      try {
        const file = await import(`../../assets/programs/${slug}/quote.ts`)
        const { multiQuoteLoader, sections } = file.default || file
        this.sections = sections
        this.multiQuoteLoader = multiQuoteLoader
        this.sectionsSlug = slug
        return sections
      } catch (err) {
        handleChunkLoadError(err)
      }
    },
    async loadReviewData (): Promise<LegacyQuoteReviewSchema | undefined> {
      const authenticationStore = useAuthenticationStore()
      const slug = authenticationStore.slug
      if (this.reviewSlug === slug) return Promise.resolve(this.reviewData as LegacyQuoteReviewSchema)
      this.reviewData = {} as LegacyQuoteReviewSchema
      this.reviewSlug = ''
      try {
        const file = await import(`../../assets/programs/${slug}/quotereview.ts`)
        const data: LegacyQuoteReviewSchema = file.default || file
        this.reviewData = data
        this.reviewSlug = slug
        return data
      } catch (err) {
        handleChunkLoadError(err)
      }
    },
  },
})

hotReloadStore(useFormQuoteStore)
