import {
  formatCurrency as utilsFormatCurrency,
  formatDate as utilsFormatDate,
  formatValue as utilsFormatValue,
} from '@policyfly/utils/formatter'
import { roundingModeToBigRoundingMode } from '@policyfly/utils/protobuf'

import { useSettingsStore } from '@/stores/settings'

import { locale } from '@/utils/locale'
import { getNestedResponse } from '@/utils/responses'

import type { FormatterProgramSettings } from '@policyfly/utils/types/formatter'

function getSettings (): FormatterProgramSettings {
  const settingsStore = useSettingsStore()
  return {
    locale,
    getEffectiveDate: () => getNestedResponse('effectiveDate') as string | null,
    cancellationRequests: settingsStore.cancellationRequests as FormatterProgramSettings['cancellationRequests'],
    decimals: settingsStore.decimals,
    roundingMode: roundingModeToBigRoundingMode(settingsStore.roundingMode),
  }
}

/**
 * See {@link utilsFormatCurrency formatCurrency}.
 * Provides default settings for use with the generic util.
 */
export function formatCurrency (value: Parameters<typeof utilsFormatCurrency>[1], options: Parameters<typeof utilsFormatCurrency>[2]): ReturnType<typeof utilsFormatCurrency> {
  return utilsFormatCurrency(getSettings(), value, options)
}

/**
 * See {@link utilsFormatDate formatDate}.
 * Provides default settings for use with the generic util.
 */
export function formatDate (date?: Parameters<typeof utilsFormatDate>[1], options?: Parameters<typeof utilsFormatDate>[2]): ReturnType<typeof utilsFormatDate> {
  return utilsFormatDate(locale(), date, options)
}

/**
 * See {@link utilsFormatValue formatValue}.
 * Provides default settings for use with the generic util.
 */
export function formatValue (v: Parameters<typeof utilsFormatValue>[1], formatter: Parameters<typeof utilsFormatValue>[2]): ReturnType<typeof utilsFormatValue> {
  try {
    return utilsFormatValue(
      getSettings(),
      v,
      formatter,
    )
  } catch (err) {
    console.error(err)
    return ''
  }
}
