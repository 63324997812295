import { request } from '../methods'

export default {
  list: request('get', '/api/v1/agency/'),
  get: request('get', '/api/v1/agency/{id}/'),
  create: request('post', '/api/v1/agency/'),
  archive: request('delete', '/api/v1/agency/{id}/'),
  restore: request('post', '/api/v1/agency/{id}/restore/'),
  rename: request('put', '/api/v1/agency/{id}/rename/'),

  listMembers: request('get', '/api/v1/agency/{id}/members/'),
  createMember: request('post', '/api/v1/agency/{id}/members/'),
  getMember: request('get', '/api/v1/agency/{id}/members/{membership_pk}/'),
  updateMember: request('put', '/api/v1/agency/{id}/members/{membership_pk}/'),
  deleteMember: request('delete', '/api/v1/agency/{id}/members/{membership_pk}/'),
  updateDenylist: request('post', '/api/v1/agency/{id}/members/{membership_pk}/denylist/'),

  listAPM: request('get', '/api/v1/agency/{agency_id}/apm/'),
  createAPM: request('post', '/api/v1/agency/{agency_id}/apm/'),
  setProgramsAPM: request('post', '/api/v1/agency/{agency_id}/apm/set_programs/'),
  getAPM: request('get', '/api/v1/agency/{agency_id}/apm/{id}/'),
  deleteAPM: request('delete', '/api/v1/agency/{agency_id}/apm/{id}/'),
}
