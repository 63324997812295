import { defineStore } from 'pinia'

import { useApiStore } from '@/stores/api'
import { useAuthenticationStore } from '@/stores/authentication'

import { api } from '@/api'
import { hotReloadStore } from '@/utils/build'

export const useOnboardingStore = defineStore({
  id: 'onboarding',

  state: () => ({
    id: 0,
    email: '',
    otp_token: '',
    password: '',
    number: '',
    first_name: '',
  }),

  getters: {
    loaded: (state) => !!state.first_name,
  },

  actions: {
    async loadUser ({ email, otp_token: otpToken }: { email: string, otp_token: string }): Promise<void> {
      const res = await api.users.fetch({ body: { email, otp_token: otpToken } })
      if (!res.data.User) throw new Error('User not found in response')
      if (res.data.User.is_active) throw new Error('User already exists')
      this.id = res.data.User.id
      this.first_name = res.data.User.first_name
      this.email = res.data.User.email
      this.otp_token = otpToken
    },
    async setupAccount (payload: { password: string }): Promise<void> {
      const apiStore = useApiStore()
      const { id } = await apiStore.user.setupAccount({
        password: payload.password,
        email: this.email,
        token: this.otp_token,
      })
      this.otp_token = ''
      const authenticationStore = useAuthenticationStore()
      authenticationStore.userId = id
    },
    provisionSMS (newNumber: string): ReturnType<typeof api['users']['provisionSMS']> {
      if (newNumber) this.number = newNumber
      const { email, number } = this
      return api.users.provisionSMS({ body: { email, phone_number: number } })
    },
    validateSMS (token: string): Promise<void> {
      const apiStore = useApiStore()
      const { email } = this
      return apiStore.user.register2FA({ email, token, mode: 'sms' })
    },
    provisionQR (): ReturnType<typeof api['users']['provisionQR']> {
      const { email } = this
      return api.users.provisionQR({ body: { email } })
    },
    validateQR (token: string): Promise<void> {
      const apiStore = useApiStore()
      const { email } = this
      return apiStore.user.register2FA({ email, token, mode: 'totp' })
    },
    provisionEmail (): ReturnType<typeof api['users']['emailChallenge']> {
      const { email } = this
      return api.users.emailChallenge({ body: { email } })
    },
    validateEmail (token: string): Promise<void> {
      const apiStore = useApiStore()
      const { email } = this
      return apiStore.user.register2FA({ email, token, mode: undefined })
    },
  },
})

hotReloadStore(useOnboardingStore)
