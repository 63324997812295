import { defineStore } from 'pinia'

import { hotReloadStore } from '@/utils/build'

import type { TSFixMe } from '@policyfly/types/common'
import type { Agency, AgencyMember } from 'types/agency'

export interface Snackbar {
  /**
   * Whether the snackbar is currently showing.
   */
  model: boolean
  type: string
  msg: string
  timeout: number
  close: boolean
}

export type ConfirmOptions =
  | ConfirmOptionsDefault
  | ConfirmOptionsAddAgency
  | ConfirmOptionsAddAgent
  | ConfirmOptionsManageAuthenticator
  | ConfirmOptionsManageSMS
  | ConfirmOptionsManageProgramsAgency
  | ConfirmOptionsManageProgramsUser
  | ConfirmOptionsDateOption
  | ConfirmOptionsEndorsementCorrection
  | ConfirmOptionsRadioOption
  | ConfirmOptionsTextField

export type ConfirmComponentNames = Extract<ConfirmOptions, { component: string }>['component'] | 'Default'

export interface ConfirmOptionsBase {
  title?: string
  showClose?: boolean
  onlyCancel?: boolean
  preventInvalidResolve?: boolean
  closeButton?: string
  confirmButton?: string
  cancelButton?: string
  body?: string
  onlyConfirm?: boolean
  persistent?: boolean
  maxWidth?: string | number
  noActions?: boolean
  icon?: string
}

export type ConfirmOptionsDefault = ConfirmOptionsBase

export interface ConfirmOptionsAddAgency extends ConfirmOptionsBase {
  component: 'AddAgency'
  /**
   * Values to use when initializing the form.
   */
  defaults?: {
    name: string
    agency_admin_first_name: string
    agency_admin_last_name: string
    agency_admin_email: string
    programs: number[]
    error?: string
  }
}

export interface ConfirmOptionsAddAgent extends ConfirmOptionsBase {
  component: 'AddAgent'
  context: {
    forceAdmin?: boolean
    /**
     * Programs that are available to add to
     */
    programs: number[]
  }
  /**
   * Values to use when initializing the form.
   */
  defaults?: {
    email: string
    first_name: string
    last_name: string
    is_administrator: boolean
    deny: number[]
    error?: string
  }
}

export type ConfirmOptionsManagePrograms =
 | ConfirmOptionsManageProgramsAgency
 | ConfirmOptionsManageProgramsUser

export interface ConfirmOptionsManageProgramsAgency extends ConfirmOptionsBase {
  component: 'ManageProgramAccess'
  /**
   * Values to use when initializing the form.
   */
  defaults: {
    agency: Agency
    programs?: number[]
  }
}

export interface ConfirmOptionsManageAuthenticator extends ConfirmOptionsBase {
  component: 'ManageAuthenticator'
  defaults: {
    qrValue: string
  }
}

export interface ConfirmOptionsManageSMS extends ConfirmOptionsBase {
  component: 'ManageSms'
}

export interface ConfirmOptionsManageProgramsUser extends ConfirmOptionsBase {
  component: 'ManageProgramAccess'
  /**
   * Values to use when initializing the form.
   */
  defaults: {
    user: AgencyMember
    programs?: number[]
  }
}
export interface ConfirmOptionsDateOption extends ConfirmOptionsBase {
  component: 'DateOption'
  rules?: ((options: TSFixMe) => string | true)[]
  context: string
}

export interface ConfirmOptionsEndorsementCorrection extends ConfirmOptionsBase {
  component: 'EndorsementCorrection'
  rules?: ((options: TSFixMe) => string | true)[]
}

export interface ConfirmOptionsRadioOption extends ConfirmOptionsBase {
  component: 'RadioOption'
  options: { label: string, value: string, disabled?: boolean }[]
}

export interface ConfirmOptionsTextField extends ConfirmOptionsBase {
  component: 'TextField'
  label: string
  initialValue?: string
  rules?: ((options: TSFixMe) => string | true)[]
}

export type Confirmation = ConfirmOptions & {
  resolve: (value: unknown) => void
  value: boolean
  key: number
  persistent: boolean
  maxWidth: string | number
}

export const useNotificationStore = defineStore({
  id: 'notification',

  state: () => ({
    snackbar: {
      model: false,
      type: '',
      msg: '',
      timeout: -1,
      close: false,
    } as Snackbar,
    confirmation: {
      title: '',
      persistent: false,
      key: 0,
      maxWidth: 400,
      value: false,
      resolve: /* istanbul ignore next: just a placeholder -- @preserve */ () => {},
    } as Confirmation,
  }),

  actions: {
    confirm (payload: ConfirmOptions) {
      return new Promise((resolve) => {
        const {
          title,
          body,
          preventInvalidResolve,
          confirmButton,
          cancelButton,
          closeButton,
          showClose,
          onlyCancel,
          onlyConfirm,
          persistent,
          maxWidth = 400,
          icon,
          noActions,
        } = payload

        this.confirmation = {
          onlyCancel,
          title,
          preventInvalidResolve,
          confirmButton,
          cancelButton,
          closeButton,
          showClose,
          body,
          resolve,
          onlyConfirm,
          persistent: persistent !== false,
          maxWidth,
          icon,
          noActions,
          component: 'component' in payload ? payload.component : undefined,
          options: 'options' in payload ? payload.options : undefined,
          context: 'context' in payload ? payload.context : undefined,
          rules: 'rules' in payload ? payload.rules : undefined,
          label: 'label' in payload ? payload.label : undefined,
          initialValue: 'initialValue' in payload ? payload.initialValue : undefined,
          defaults: 'defaults' in payload ? payload.defaults : undefined,
          value: true,
          key: Date.now(),
        } as Confirmation
      })
    },
    setDefaultSnackbar (payload: { type: string, msg: string }) {
      this.snackbar = {
        model: true,
        type: payload.type,
        msg: payload.msg,
        timeout: 7000,
        close: true,
      }
    },
    clearConfirmationContext () {
      // @ts-expect-error: Context cannot be null
      if ('context' in this.confirmation) this.confirmation.context = null
    },
  },
})

hotReloadStore(useNotificationStore)
