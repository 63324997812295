import { api } from '@/api'

import type { ApiVariableEndpoints, CreateEndpointParams } from '@/stores/api'

export interface UserApiEndpoints {
  /**
   * Registers a 2FA device that has already been provisioned from one of the following endpoints:
   * - {@link api.users.provisionSMS provisionSMS}
   * - {@link api.users.provisionQR provisionQR}
   * - {@link api.users.emailChallenge emailChallenge}
   *
   * Generally this is only used during onboarding to validate the first 2FA device for a user.
   * However it can also be used to update a QR (aka TOTP) device.
   */
  register2FA: (params: { email: string, token: string, mode: 'sms' | 'totp' | undefined }) => Promise<void>
  /**
   * Validates that a user has an active account.
   *
   * @returns Information about available 2FA devices for this account.
   */
  validateUser: (params: { email: string, password: string }) => Promise<{
    hasAuthenticator: boolean
    hasSms: boolean
    hasEmail: boolean
  }>
  /**
   * Authenticates a user with the provided login information.
   * Will update the stored API tokens for for any future requests.
   */
  login: (params: { email: string, password: string, token: string, rememberMe: boolean }) => Promise<void>
  /**
   * Sets up the user account for the first time and logs them in.
   * Will update the stored API tokens for for any future requests.
   */
  setupAccount: (params: { email: string, password: string, token: string }) => Promise<{ id: number }>
}

/**
 * Creates endpoints related to the Application workflow.
 */
export const createUserEndpoints = (params: CreateEndpointParams): ApiVariableEndpoints<UserApiEndpoints> => {
  const register2FA: UserApiEndpoints['register2FA'] = async ({ email, token, mode }) => {
    await api.users.auth({ body: { email, otp_token: token, device_mode: mode } })
  }

  const validateUser: UserApiEndpoints['validateUser'] = async ({ email, password }) => {
    const res = await api.users.auth({ body: { email, password } })

    return {
      hasAuthenticator: res.data.has_authenticator,
      hasSms: res.data.has_sms,
      hasEmail: res.data.has_emaildevice,
    }
  }

  function loadTokens (data: { session_length: number, spa_token: string }): void {
    // @ts-expect-error: Swagger definitions do not exist on the api endpoints
    const jwt: { access: string, refresh: string } = data.jwt
    params.refreshToken.value = jwt.refresh
    params.accessToken.value = jwt.access

    const expirationDate = new Date()
    expirationDate.setHours(expirationDate.getHours() + data.session_length! / 60)
    params.spaToken.value = data.spa_token
    params.spaTokenExpiration.value = expirationDate.toISOString()
  }

  const login: UserApiEndpoints['login'] = async ({ email, password, token, rememberMe }) => {
    const res = await api.users.auth({ body: { email, password, otp_token: token, remember_me: rememberMe } })

    if (!res.data.token_validated) throw new Error('Invalid token.')

    loadTokens(res.data)
  }

  const setupAccount: UserApiEndpoints['setupAccount'] = async ({ email, password, token }) => {
    const res = await api.users.setupAccount({ body: { email, password, otp_token: token } })

    loadTokens(res.data)

    return {
      id: res.data.user.id,
    }
  }

  const endpoints: UserApiEndpoints = {
    register2FA,
    validateUser,
    login,
    setupAccount,
  }
  return {
    django: endpoints,
    grpc: endpoints,
  }
}
