/* eslint-disable @typescript-eslint/explicit-function-return-type */
import routeNames from '@/router/routeNames'

import type { StrictRouteConfig } from 'types/router'

const ApplicationLayout = () => import('@/layouts/ApplicationLayout.vue')
const FeedPage = () => import('@/pages/Application/FeedPage.vue')
const ReviewPage = () => import('@/pages/Application/ApplicationReviewPage.vue')
const AttachmentsPage = () => import('@/pages/Application/AttachmentsPage.vue')
const PolicyDocsPage = () => import('@/pages/Application/PolicyDocsPage.vue')
const PrintPage = () => import('@/pages/Application/PrintPage.vue')

export const routeConfig: StrictRouteConfig[] = [
  {
    path: '/policy/:policyId(\\d+)',
    meta: { requiresAuth: true, applicationLayout: true },
    component: ApplicationLayout,
    props: true,
    children: [
      {
        path: '',
        redirect: { name: routeNames.POLICY_FEED },
      },
      {
        path: 'feed',
        redirect: { name: routeNames.POLICY_FEED },
      },
      {
        path: 'activity',
        component: FeedPage,
        name: routeNames.POLICY_FEED,
        meta: { isTabsList: true, policyView: true },
      },
      {
        name: routeNames.POLICY_REVIEW,
        path: 'details',
        component: ReviewPage,
        meta: { isTabsList: true, policyView: true },
      },
      {
        path: 'attachments',
        name: routeNames.POLICY_ATTACHMENTS,
        component: AttachmentsPage,
        meta: { isTabsList: true, policyView: true },
      },
      {
        path: 'policy',
        name: routeNames.POLICY_POLICY_DOCS,
        component: PolicyDocsPage,
        meta: { isTabsList: true, policyView: true },
      },
      {
        name: routeNames.POLICY_PRINT,
        path: 'print',
        props: (route) => ({
          nestedDataPath: route.query.loc,
        }),
        component: PrintPage,
        meta: { isTabsList: false, hiddenFAB: true, policyView: true },
      },
    ],
  },
]

export default routeConfig
