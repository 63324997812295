import {
  AttachmentCategory,
  AttachmentVisibility,
  GenericStatus,
  Kind,
  VirusScanStatus,
  VehicleType,
  AttachmentStatus,
  CommentVisibility,
  EventVisibility,
  Task,
  SchemaTranslations_FeedEventLink_RouteName,
} from '@policyfly/protobuf'
import {
  dateToString,
  stringToDate,
  stringToTimestamp,
  timestampToMilliseconds,
  timestampToString,
} from '@policyfly/utils/protobuf'

import { TASK_STATUS_MAP } from '@/constants'
import { i18n } from '@/plugins/i18n'
import { bytesToDataURL } from '@/utils/api'
import { getDateToday } from '@/utils/date'
import { formatDate } from '@/utils/formatter'

import type { ChangelogItem } from '@/components/task/TaskDialog/TaskDialogTypes'
import type { ReadonlyPolicy, ReadonlyPolicyState } from '@/stores/protobuf'
import type { Attachment, Comment, EventEntryResponse, Policy as PolicyGrpc, PolicyState, TaskListDetail, User, ReadTaskResponse } from '@policyfly/protobuf'
import type { SystemAttachmentCategory, UserDefinedAttachmentCategory } from '@policyfly/schema/types/shared/attachmentCategory'
import type { ApplicationStatus } from '@policyfly/types/application'
import type { PolicyStatus } from '@policyfly/types/policy'
import type {
  APIApplication,
  Application,
  ApplicationAttachment,
  ApplicationKind,
} from 'types/application'
import type { FeedEventLog } from 'types/events'
import type {
  PolicyCoveragePeriod,
  Policy as PolicyJson,
} from 'types/policy'
import type { RouteNamesValues } from 'types/router'
import type { TaskDetail, TaskDocument } from 'types/tasks'
import type { User as UserJson } from 'types/user'

/**
 * @todo Wire up remaining properties
 *
 * Converts a PolicyState to an equivalent Django Application structure.
 * Used to preserve backwards compatibility for older programs.
 */
export function policyStateToApplication (policyState: PolicyState | ReadonlyPolicyState, attachments: Attachment[] = [], events: FeedEventLog[] = []): APIApplication {
  const policyModified = timestampToMilliseconds(policyState.modifiedAt)
  const latestEventDate = new Date(events?.[0]?.created || 0)
  const latestEvent = latestEventDate.getTime()

  const modified = Math.max(policyModified, latestEvent)
    ? new Date(Math.max(policyModified, latestEvent)).toISOString()
    : ''

  const kind = kindToApplicationKind(policyState.kind)
  const category = (() => {
    switch (policyState.kind) {
      case Kind.KIND_CANCELLATION: return 'CAN'
      case Kind.KIND_ENDORSEMENT: return 'END'
      case Kind.KIND_REINSTATEMENT: return 'REI'
      case Kind.KIND_RENEWAL: return 'REN'
      default: return 'NEW'
    }
  })()

  return {
    id: policyState.id,
    agency: policyState.agency as unknown as APIApplication['agency'],
    archived: false, // TODO: wire up
    assignee: userToUserJson(policyState.assignee),
    // @ts-expect-error: Attachment in swagger doesn't match our types
    attachments: attachments.map((file) => attachmentToApplicationAttachment(file)),
    // @ts-expect-error: Swagger category doesn't include 'CAN'
    category,
    childID: null, // TODO: wire up
    computedData: {}, // not relevant
    created: timestampToString(policyState.createdAt)!,
    data: {}, // not relevant
    date_entered_reviewing: null, // TODO: wire up
    derivedData: null, // TODO: wire up
    diffData: {}, // not relevant
    effective: dateToString(policyState.effective),
    endorsement_iterator: Number(policyState.endorsementIterator || 0),
    events, // refreshed using the feed store
    expiration: dateToString(policyState.expiration),
    is_cancellation: policyState.kind === Kind.KIND_CANCELLATION,
    kind,
    last_modified_by: null, // TODO: wire up
    last_rating: null, // not relevant
    last_rating_error: '', // not relevant
    license_data: null, // TODO: wire up
    lock_code: null, // TODO: wire up
    lock_description: '', // TODO: wire up
    modified,
    owned_by: userToUserJson(policyState.owner),
    owner_role_in_program: '', // TODO: wire up
    lastDescendantID: null, // TODO: wire up
    parentID: null, // TODO: wire up
    policy: policyGrpcToJson(policyState.policy ?? {} as PolicyGrpc, [], true) as unknown as APIApplication['policy'],
    program: policyState.program!, // TODO: wire up
    quote_set: policyState.selectedQuote ? { selected: policyState.selectedQuote.uuid4 } : {}, // TODO: wire up
    // @ts-expect-error: API type is wrong, it can be null
    selectedQuote: null, // TODO: wire up
    status: genericStatusToApplicationStatus(policyState.status),
    substatus: null, // TODO: wire up
    unread_comment_count: 0, // TODO: wire up
  }
}

/**
 * @todo Wire up remaining properties
 *
 * Converts a Policy to an equivalent Django Policy structure.
 * Used to preserve backwards compatibility for older programs.
 */
export function policyGrpcToJson (policy: PolicyGrpc | ReadonlyPolicy, attachments: Attachment[] = [], shallow = false): PolicyJson {
  const unknownUser: PolicyJson['last_modified_by'] = {
    id: 0,
    email: '',
    first_name: '',
    last_name: '',
    avatar_url: '',
    is_active: false,
    active_at: '',
    isClerk: false,
    agency_memberships: [],
    membership: [],
  }
  // Remove any discarded applications
  const applications = policy.states?.filter((policyState) => ![GenericStatus.TERMINATED, GenericStatus.RENEWAL_DECLINED, GenericStatus.RENEWAL_OFFERED, GenericStatus.READY_FOR_RENEWAL].includes(policyState.status))
    .map((policyState) => policyStateToApplication(policyState) as unknown as Application) || []
  const latestApplication = applications.length ? applications[0] : null
  const latestIssuedApplication = applications.find((application) => application.status === 'ISSUED') ?? null
  const periods: PolicyCoveragePeriod[] = applications?.map((application) => ({
    effective: application.effective ?? '',
    expiration: application.expiration ?? '',
    category: application.category,
    id: application.id,
    kind: application.kind,
  }))

  const today = new Date(getDateToday())
  const canReinstate = policy.status === GenericStatus.CANCELLED &&
    policy.reinstatementWindow && new Date(dateToString(policy.reinstatementWindow)!) > today
  const canRenew = policy.renewalWindow && latestIssuedApplication && latestIssuedApplication.expiration &&
    new Date(latestIssuedApplication.expiration) > today && today > new Date(dateToString(policy.renewalWindow)!)

  return {
    id: policy.id,
    actionable_applications: [], // TODO: wire up
    active_tab_label: '', // not needed
    applications,
    attachments: attachments.map((file) => attachmentToApplicationAttachment(file)),
    can_reclaim: false, // TODO: wire up
    can_reinstate: !!canReinstate,
    can_renew: !!canRenew,
    renewal_forbidden: policy.status === GenericStatus.RENEWAL_DECLINED
      ? true // allow renewal to be "reconsidered" if already declined
      : policy.status !== GenericStatus.RENEWAL_OFFERED,
    children: [], // TODO: wire up
    coverage_periods: periods,
    created: '', // TODO: wire up
    effective: dateToString(policy.term?.effective) ?? latestIssuedApplication?.effective ?? '',
    events: [], // should be loaded through the event service
    expiration: dateToString(policy.term?.expiration) ?? latestIssuedApplication?.expiration ?? '',
    first_child: null, // TODO: wire up
    has_children: false, // TODO: wire up
    has_reclaim: false, // TODO: wire up
    home_tab_label: '', // not needed
    inactive_tab_label: '', // not needed
    is_home: false, // not needed
    is_policies_active: false, // not needed
    is_policies_inactive: false, // not needed
    is_reclaim: false, // TODO: wire up
    last_modified_by: unknownUser, // TODO: wire up
    // @ts-expect-error: Forcing null to avoid recursion
    latest_application: shallow ? null : latestApplication,
    // @ts-expect-error: Forcing null to avoid recursion
    latest_issued_app: shallow ? null : latestIssuedApplication,
    modified: '', // TODO: wire up
    parent: null, // will always be null for protobuf Policy
    PNI: policy.primaryNamedInsured?.legalName ?? '',
    status: genericStatusToPolicyStatus(policy.status),
  }
}

const genericStatusToPolicyStatusMap = new Map<GenericStatus, PolicyStatus>([
  [GenericStatus.CANCELLED, 'CANCELLED'],
  [GenericStatus.CANCELLED_WITH_ERROR, 'CANCELLED'],
  [GenericStatus.DECLINED, 'INACTIVE'],
  [GenericStatus.DRAFT, 'INACTIVE'],
  [GenericStatus.EXPIRED, 'EXPIRED'],
  [GenericStatus.IN_FORCE, 'ACTIVE'],
  [GenericStatus.QUALITY_CONTROL, 'INACTIVE'],
  [GenericStatus.QUOTED, 'INACTIVE'],
  [GenericStatus.REQUESTED, 'INACTIVE'],
  [GenericStatus.SUBMITTED, 'ACTIVE'],
  [GenericStatus.UNBOUND, 'INACTIVE'],
  [GenericStatus.READY_FOR_RENEWAL, 'ACTIVE'],
  [GenericStatus.RENEWAL_DECLINED, 'ACTIVE'],
  [GenericStatus.RENEWAL_OFFERED, 'ACTIVE'],
  [GenericStatus.RENEWING, 'ACTIVE'],
  [GenericStatus.RENEWED, 'ACTIVE'],
  [GenericStatus.UNKNOWN, 'INACTIVE'],
])
/**
 * Converts a {@link GenericStatus} to a {@link PolicyStatus}.
 */
export function genericStatusToPolicyStatus (status: GenericStatus): PolicyStatus {
  return genericStatusToPolicyStatusMap.get(status) ?? 'INACTIVE'
}

const policyStatusToGenericStatusMap = new Map<PolicyStatus, GenericStatus>([
  ['CANCELLED', GenericStatus.CANCELLED],
  ['INACTIVE', GenericStatus.DRAFT],
  ['EXPIRED', GenericStatus.EXPIRED],
  ['ACTIVE', GenericStatus.SUBMITTED],
])
/**
 * Converts a {@link PolicyStatus} to a {@link GenericStatus}.
 */
export function policyStatusToGenericStatus (status: PolicyStatus): GenericStatus {
  return policyStatusToGenericStatusMap.get(status) ?? GenericStatus.UNKNOWN
}

const genericStatusToApplicationStatusMap = new Map<GenericStatus, ApplicationStatus>([
  [GenericStatus.AUTHORIZATION, 'PENDING_AUTHORISATION'],
  [GenericStatus.CANCELLED, 'ISSUED'],
  [GenericStatus.CANCELLED_WITH_ERROR, 'DECLINED'],
  [GenericStatus.DECLINED, 'DECLINED'],
  [GenericStatus.DRAFT, 'DRAFT'],
  [GenericStatus.EXPIRED, 'TERMINATED'],
  [GenericStatus.IN_FORCE, 'ISSUED'],
  [GenericStatus.NOT_TAKEN_UP_APPLICATION, 'DECLINED'],
  [GenericStatus.NOT_TAKEN_UP_QUOTE, 'DECLINED'],
  [GenericStatus.PENDING_APPROVAL, 'PENDING_APPROVAL'],
  [GenericStatus.PENDING_BINDER, 'PENDING_BINDER'],
  [GenericStatus.PENDING_FINALISATION, 'PENDING_FINALISATION'],
  [GenericStatus.PENDING_ISSUE, 'PENDING_ISSUE'],
  [GenericStatus.QUALITY_CONTROL, 'PENDING_QUALITY_CONTROL'],
  [GenericStatus.QUOTED, 'QUOTED'],
  [GenericStatus.QUOTE_LOST, 'DECLINED'],
  [GenericStatus.RENEWAL_DECLINED, 'ISSUED'],
  [GenericStatus.RENEWAL_OFFERED, 'ISSUED'],
  [GenericStatus.READY_FOR_RENEWAL, 'ISSUED'],
  [GenericStatus.RENEWING, 'ISSUED'],
  [GenericStatus.RENEWED, 'ISSUED'],
  [GenericStatus.REQUESTED, 'REQUESTED_TO_BIND'],
  [GenericStatus.SUBMITTED, 'REVIEW'],
  [GenericStatus.TERMINATED, 'TERMINATED'],
  [GenericStatus.UNBOUND, 'PENDING_ISSUE'],
])
/**
 * Converts a {@link GenericStatus} to a {@link ApplicationStatus}.
 */
export function genericStatusToApplicationStatus (status: GenericStatus): ApplicationStatus {
  return genericStatusToApplicationStatusMap.get(status) ?? 'DRAFT'
}

const applicationStatusToGenericStatusMap = new Map<ApplicationStatus, GenericStatus>([
  ['DECLINED', GenericStatus.CANCELLED_WITH_ERROR],
  ['DRAFT', GenericStatus.DRAFT],
  ['ISSUED', GenericStatus.IN_FORCE],
  ['PENDING_APPROVAL', GenericStatus.PENDING_APPROVAL],
  ['PENDING_AUTHORISATION', GenericStatus.AUTHORIZATION],
  ['PENDING_BINDER', GenericStatus.PENDING_BINDER],
  ['PENDING_FINALISATION', GenericStatus.PENDING_FINALISATION],
  ['PENDING_ISSUE', GenericStatus.PENDING_ISSUE],
  ['PENDING_QUALITY_CONTROL', GenericStatus.QUALITY_CONTROL],
  ['QUOTED', GenericStatus.QUOTED],
  ['REQUESTED_TO_BIND', GenericStatus.REQUESTED],
  ['REVIEW', GenericStatus.SUBMITTED],
  ['TERMINATED', GenericStatus.TERMINATED],
])
/**
 * Converts a {@link ApplicationStatus} to a {@link GenericStatus}.
 */
export function applicationStatusToGenericStatus (status: ApplicationStatus, isCancellation: boolean): GenericStatus {
  const mappedStatus = applicationStatusToGenericStatusMap.get(status) ?? GenericStatus.UNKNOWN
  if (mappedStatus === GenericStatus.IN_FORCE && isCancellation) return GenericStatus.CANCELLED
  return mappedStatus
}

/**
 * @todo Wire up remaining properties
 *
 * Converts an Protobuf Alexandria {@link Attachment} to an equivalent Django {@link ApplicationAttachment} structure.
 * Django fields pointing to file location are not needed in protobuf since we're also provided the file data.
 */
export function attachmentToApplicationAttachment (attachment: Attachment): ApplicationAttachment {
  const unknownUser: ApplicationAttachment['owned_by'] = {
    id: 0,
    email: '',
    first_name: '',
    last_name: '',
    avatar_url: '',
    is_active: false,
    active_at: '',
    isClerk: false,
    agency_memberships: [],
    membership: [],
  }

  const mimeType = attachment.metadata?.mimeType
  const status = attachmentStatusToApplicationAttachmentPDFStatus(attachment.metadata?.status) ?? 'PASSED'
  const downloadUrl = bytesToDataURL(attachment.data, mimeType)
  const previewUrl = attachment.previewData?.length
    ? bytesToDataURL(attachment.previewData, 'application/pdf')
    : null

  return {
    applications: attachment.metadata?.applicationIds.map((id) => Number(id)) || [],
    archived: attachment.metadata?.archiveStatus?.archived || false,
    category: attachmentCategoryToSystemAttachmentCategory(attachment.metadata?.category),
    document_type: attachment.metadata?.name.replace(/\.pdf$/i, '') ?? '',
    id: Number(attachment.metadata?.id ?? 0),
    luid: '', // possible legacy code
    created: timestampToString(attachment.metadata?.created, true) ?? '',
    modified: timestampToString(attachment.metadata?.modified, true) ?? '',
    original_name: attachment.metadata?.name || '',
    original_url: downloadUrl,
    owned_by: unknownUser,
    pdf_download_url: '', // not needed
    pdf_status: status,
    pdf_url: previewUrl,
    policy_document: attachment.metadata?.policyDocument || false,
    resource: '', // only used for files other than pdf & xlsx
    restorable_from_soft_archive: attachment.metadata?.archiveStatus?.restorableFromSoftArchive || false,
    restricted_visibility: attachment.metadata?.visibility === AttachmentVisibility.Visibility_Restricted,
    signature_confirmed: null, // possible legacy code
    signature_confirmed_by: null, // possible legacy code
    signature_status: '', // possible legacy code
    size: Number(attachment.metadata?.sizeBytes ?? 0),
    soft_archived: attachment.metadata?.archiveStatus?.softArchived || false,
    system_generated: attachment.metadata?.systemGenerated || false,
    virus_status: virusScanStatusToString(attachment.metadata?.virusStatus),
    xlsx_download_url: null, // not needed
    xlsx_status: mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? status : null,
    xlsx_url: mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? downloadUrl : null,
    lazy: !previewUrl,
  }
}

/**
 * Converts Protobuf Alexandria {@link AttachmentCategory} status
 * to equivalent Django {@link SystemAttachmentCategory} or {@link UserDefinedAttachmentCategory} status
 */
export function attachmentCategoryToSystemAttachmentCategory (category: AttachmentCategory | undefined): string {
  return attachmentCategoryToSystemAttachmentCategoryMap.get(category ?? AttachmentCategory.Attachment_Category_Unspecified) || 'XXX'
}

const attachmentCategoryToSystemAttachmentCategoryMap = new Map<AttachmentCategory, SystemAttachmentCategory | UserDefinedAttachmentCategory>([
  [AttachmentCategory.Attachment_Category_Unspecified, 'XXX'],
  [AttachmentCategory.Attachment_Category_Not_Applicable, 'XXX'],
  [AttachmentCategory.Attachment_Category_Inquiry, 'INQ'],
  [AttachmentCategory.Attachment_Category_Application, 'APP'],
  [AttachmentCategory.Attachment_Category_Quote, 'QUO'],
  [AttachmentCategory.Attachment_Category_Endorsement, 'END'],
  [AttachmentCategory.Attachment_Category_Certificate, 'CER'],
  [AttachmentCategory.Attachment_Category_Broker_Invoice, 'INV'],
  [AttachmentCategory.Attachment_Category_Insured_Invoice, 'INI'],
  [AttachmentCategory.Attachment_Category_Declarations, 'DEC'],
  [AttachmentCategory.Attachment_Category_Claim, 'CLA'],
  [AttachmentCategory.Attachment_Category_Addendum, 'ADD'],
  [AttachmentCategory.Attachment_Category_Medical_Certification, 'MED'],
  [AttachmentCategory.Attachment_Category_Temporary_Document, 'TMP'],
  [AttachmentCategory.Attachment_Category_Quote_Summary, 'QSM'],
  [AttachmentCategory.Attachment_Category_Endorsement_Quote_Summary, 'EQS'],
  [AttachmentCategory.Attachment_Category_Binder, 'BND'],
  [AttachmentCategory.Attachment_Category_Order_To_Bind, 'OTB'],
  [AttachmentCategory.Attachment_Category_Confirmation_of_Coverage, 'COC'],
  [AttachmentCategory.Attachment_Category_Loss, 'LOR'],
  [AttachmentCategory.Attachment_Category_APD_Loss, 'APL'],
  [AttachmentCategory.Attachment_Category_MTC_Loss, 'MTL'],
  [AttachmentCategory.Attachment_Category_Drivers_Outside_Criteria, 'DRI'],
])

/**
 * Converts Django {@link SystemAttachmentCategory} or {@link UserDefinedAttachmentCategory} status
 * to equivalent Protobuf Alexandria {@link AttachmentCategory} status.
 */
export function systemAttachmentCategoryToAttachmentCategory (category: SystemAttachmentCategory | UserDefinedAttachmentCategory | '' | undefined): AttachmentCategory {
  return systemAttachmentCategoryToAttachmentCategoryMap.get(category || 'XXX') ?? AttachmentCategory.Attachment_Category_Unspecified
}

const systemAttachmentCategoryToAttachmentCategoryMap = new Map<SystemAttachmentCategory | UserDefinedAttachmentCategory, AttachmentCategory>([
  ['XXX', AttachmentCategory.Attachment_Category_Unspecified],
  ['INQ', AttachmentCategory.Attachment_Category_Inquiry],
  ['APP', AttachmentCategory.Attachment_Category_Application],
  ['QUO', AttachmentCategory.Attachment_Category_Quote],
  ['END', AttachmentCategory.Attachment_Category_Endorsement],
  ['CER', AttachmentCategory.Attachment_Category_Certificate],
  ['INV', AttachmentCategory.Attachment_Category_Broker_Invoice],
  ['INI', AttachmentCategory.Attachment_Category_Insured_Invoice],
  ['DEC', AttachmentCategory.Attachment_Category_Declarations],
  ['CLA', AttachmentCategory.Attachment_Category_Claim],
  ['ADD', AttachmentCategory.Attachment_Category_Addendum],
  ['MED', AttachmentCategory.Attachment_Category_Medical_Certification],
  ['TMP', AttachmentCategory.Attachment_Category_Temporary_Document],
  ['QSM', AttachmentCategory.Attachment_Category_Quote_Summary],
  ['EQS', AttachmentCategory.Attachment_Category_Endorsement_Quote_Summary],
  ['BND', AttachmentCategory.Attachment_Category_Binder],
  ['OTB', AttachmentCategory.Attachment_Category_Order_To_Bind],
  ['COC', AttachmentCategory.Attachment_Category_Confirmation_of_Coverage],
  ['LOR', AttachmentCategory.Attachment_Category_Loss],
  ['APL', AttachmentCategory.Attachment_Category_APD_Loss],
  ['MTL', AttachmentCategory.Attachment_Category_MTC_Loss],
  ['DRI', AttachmentCategory.Attachment_Category_Drivers_Outside_Criteria],
])

/**
 * Convert Protobuf Alexandria @VirusScanStatus to equivalent Django status
 */
export function virusScanStatusToString (status: VirusScanStatus | undefined): string {
  return virusScanStatusToStringMap.get(status ?? VirusScanStatus.Virus_Scan_Status_Unspecified) ?? 'PASSED'
}

const virusScanStatusToStringMap = new Map<VirusScanStatus, string>([
  [VirusScanStatus.Virus_Scan_Status_Failed, 'FAILED'],
  [VirusScanStatus.Virus_Scan_Status_Passed, 'PASSED'],
  [VirusScanStatus.Virus_Scan_Status_Pending, 'PENDING'],
  [VirusScanStatus.Virus_Scan_Status_Unavailable, ''],
  [VirusScanStatus.Virus_Scan_Status_Unspecified, ''],
])

/**
 * Converts Django vehicle type string to Protobuf {@link VehicleType}.
 */
export function stringToVehicleType (type: string | undefined): VehicleType {
  return stringToVehicleTypeMap.get(type ?? '') ?? VehicleType.UNKNOWN_VEHICLE_TYPE
}

const stringToVehicleTypeMap = new Map<string, VehicleType>([
  ['Truck', VehicleType.TRUCK],
  ['Trailer', VehicleType.TRAILER],
  ['Tractor', VehicleType.TRACTOR],
  ['Motorcycle', VehicleType.MOTORCYCLE],
  ['Passenger Car', VehicleType.PASSENGER_CAR],
  ['Camper', VehicleType.CAMPER],
  ['All Terrain Vehicle', VehicleType.ALL_TERRAIN],
  ['Other Vehicle', VehicleType.OTHER_VEHICLE_TYPE],
  ['Unknown Vehicle', VehicleType.UNKNOWN_VEHICLE_TYPE],
])

/**
 * Converts Protobuf vehicle enum type to Django string {@link VehicleType}.
 */
export function vehicleTypeToString (type: VehicleType | undefined): string {
  return vehicleTypeToStringMap.get(type ?? VehicleType.UNKNOWN_VEHICLE_TYPE) ?? 'Unknown Vehicle'
}

export const vehicleTypeToStringMap = new Map(Array.from(stringToVehicleTypeMap, ([name, type]) => [type, name]))

/**
 * Converts Django {@link UserJson} to equivalent Protobuf {@link User}
 */
export function userJsonToUser (user?: Partial<UserJson>): User {
  return {
    id: user?.id || 0,
    email: user?.email || '',
    firstName: user?.first_name || '',
    lastName: user?.last_name || '',
  }
}

/**
 * Converts Protobuf {@link User} to equivalent Django {@link UserJson}
 */
export function userToUserJson (user?: Partial<User>): UserJson | null {
  if (!user) return null
  return {
    id: user?.id || 0,
    email: user?.email || '',
    first_name: user?.firstName || '',
    last_name: user?.lastName || '',
    avatar_url: '',
    is_active: false,
    active_at: '',
    isClerk: false,
  } as UserJson
}

/**
 * Converts Protobuf {@link User} to equivalent Django mentioned_user for comments
 **/
export function userToMention (user?: Partial<User>): Pick<UserJson, 'email' | 'first_name' | 'last_name'> {
  return {
    email: user?.email || '',
    first_name: user?.firstName || '',
    last_name: user?.lastName || '',
  }
}

/**
 * Converts Protobuf {@link EventEntryResponse} to equivalent Django {@link FeedEventLog}
 */
export function eventEntryResponseToFeedEventLog (event: EventEntryResponse): FeedEventLog {
  const created = new Date(event.created ? +event.created.seconds * 1000 : 0)
  const name = event.userName.split(' ')
  return {
    id: +event.id,
    application: 0,
    application_src: '',
    comment: null,
    created: event.created ? created.toISOString() : '',
    created_by: {
      id: 0,
      first_name: name[0],
      last_name: name.length > 1 ? name[1] : '',
    },
    disabled: false,
    policy: 0,
    policy_src: 0,
    verb: event.data?.data.oneofKind || '',
    data: event.data?.data || {},
    visibility: event.visibility,
  }
}

/**
 * Converts Protobuf {@link Comment}
 * to equivalent Django {@link FeedEventLog}
 */
export function commentToFeedEventLog (comment: Comment): FeedEventLog {
  const created = new Date(comment.created ? +comment.created.seconds * 1000 : 0)
  const attachment = comment.attachments.length ? comment.attachments[0] : null
  return {
    // @ts-expect-error: allow uuids for now
    id: comment.uuid,
    application: +comment.applicationId || undefined,
    application_src: '',
    comment: {
      read_by: comment.readBy ? userToUserJson(comment.readBy) : undefined,
      // @ts-expect-error: allow uuids for now
      id: comment.uuid,
      creator_role: '',
      restricted_visibility: [CommentVisibility.COMMENT_VISIBILITY_RESTRICTED, CommentVisibility.COMMENT_VISIBILITY_STAFF].includes(comment.visibility!),
      body: comment.content,
    },
    created: comment.created ? created.toISOString() : '',
    created_by: {
      id: 0,
      first_name: comment.author?.firstName ?? '',
      last_name: comment.author?.lastName ?? '',
    },
    disabled: false,
    policy: +comment.policyId || undefined,
    policy_src: 0,
    ...(attachment
      ? {
          verb: 'uploaded',
          description: { text: attachment.metadata?.name || '' },
          data: { original_name: attachment.metadata?.name, policy_document: false },
        }
      : {
          verb: 'commented',
          data: {},
        }),
    visibility: EventVisibility.EVENT_VISIBILITY_UNSPECIFIED,
    readOnly: comment.readable === false,
  }
}

/**
 * Converts Protobuf Kind {@link Kind}
 * to equivalent Django {@link ApplicationKind}
 */
export function kindToApplicationKind (kind: Kind): ApplicationKind {
  return kindToApplicationKindMap.get(kind) ?? 'APPLICATION'
}

const kindToApplicationKindMap = new Map<Kind, ApplicationKind>([
  [Kind.KIND_APPLICATION, 'APPLICATION'],
  [Kind.KIND_RENEWAL, 'RENEWAL'],
  [Kind.KIND_ENDORSEMENT, 'ENDORSEMENT'],
  [Kind.KIND_CANCELLATION, 'ENDORSEMENT'],
  [Kind.KIND_REINSTATEMENT, 'ENDORSEMENT'],
])

/**
 * Converts Django {@link ApplicationKind}
 * to equivalent Protobuf Kind {@link Kind}
 */
export function applicationKindToKind (kind: ApplicationKind): Kind {
  return applicationKindToKindMap.get(kind) ?? Kind.KIND_APPLICATION
}

const applicationKindToKindMap = new Map<ApplicationKind, Kind>([
  ['APPLICATION', Kind.KIND_APPLICATION],
  ['RENEWAL', Kind.KIND_RENEWAL],
  ['ENDORSEMENT', Kind.KIND_ENDORSEMENT],
])

/**
 * Converts Protobuf {@link AttachmentStatus}
 * to equivalent Django {@link ApplicationAttachment.pdf_status}
 */
const attachmentStatusToApplicationAttachmentPDFStatusMap = new Map<AttachmentStatus, ApplicationAttachment['pdf_status']>([
  [AttachmentStatus.ATTACHMENT_STATUS_UNSPECIFIED, 'PASSED'],
  [AttachmentStatus.ATTACHMENT_STATUS_FAILED, 'FAILED'],
  [AttachmentStatus.ATTACHMENT_STATUS_READY, 'PASSED'],
  [AttachmentStatus.ATTACHMENT_STATUS_GENERATING, 'PENDING'],
])

export function attachmentStatusToApplicationAttachmentPDFStatus (status?: AttachmentStatus): ApplicationAttachment['pdf_status'] {
  return attachmentStatusToApplicationAttachmentPDFStatusMap.get(status ?? AttachmentStatus.ATTACHMENT_STATUS_UNSPECIFIED) ?? null
}

export function taskToTaskDetail (response: ReadTaskResponse): TaskDetail {
  const task = response.task!
  return {
    // @ts-expect-error: allow uuids for now
    id: task.id,
    policy_id: +task.policyId,
    title: task.title,
    description: task.description,
    due_date: dateToString(task.dueDate),
    assignee: task.assignee?.id ? userToUserJson(task.assignee) : null,
    restricted_visibility: task.internal,
    subjectivity: task.subjectivity,
    program_id: +task.programId,
    status: task.completed ? 'CLOSED' : 'OPEN',
    created: timestampToString(task.createdAt, true) ?? '',
    created_by: userToUserJson(task.createdBy)!,
    last_modified_by: userToUserJson(task.lastModifiedBy)!,
    modified: timestampToString(task.modifiedAt, true) ?? '',
    archived: false,
    changelogs: [],
  }
}

export function taskToTaskDocument (taskDetail: TaskListDetail): TaskDocument {
  const task = taskDetail.task!
  return {
    // @ts-expect-error: allow uuids for now
    id: task.id,
    policy_id: +task.policyId,
    title: task.title,
    description: task.description,
    due_date: dateToString(task.dueDate),
    // @ts-expect-error swagger type is wrong, assignee can be null
    assignee: task.assignee?.id ? userToUserJson(task.assignee) : null,
    restricted_visibility: task.internal,
    subjectivity: task.subjectivity,
    program_id: +task.programId,
    primary_named_insured: taskDetail.meta?.primaryNamedInsured || '',
    status: task.completed ? 'CLOSED' : 'OPEN',
    created: timestampToString(task.createdAt, true) ?? '',
    created_by: userToUserJson(task.createdBy)!,
    last_modified_by: userToUserJson(task.lastModifiedBy)!,
    modified: timestampToString(task.modifiedAt, true) ?? '',
    effective: null,
  }
}

export function taskDocumentToTask (task: TaskDocument): Task {
  return Task.create({
    id: task.id ? String(task.id) : undefined,
    title: task.title,
    description: task.description,
    dueDate: stringToDate(task.due_date) ?? undefined,
    policyId: task.policy_id ? String(task.policy_id) : undefined,
    programId: task.program_id ? String(task.program_id) : undefined,
    assignee: userJsonToUser(task.assignee),
    assigneeId: task.assignee?.id ? String(task.assignee?.id) : undefined,
    internal: task.restricted_visibility,
    subjectivity: task.subjectivity,
    completed: task.status === 'CLOSED',
    createdAt: stringToTimestamp(task.created) ?? undefined,
    createdBy: task.created_by ? userJsonToUser(task.created_by) : undefined,
    modifiedAt: stringToTimestamp(task.modified) ?? undefined,
    lastModifiedBy: task.last_modified_by ? userJsonToUser(task.last_modified_by) : undefined,
  })
}

export function changelogToChangelogItem (changelog: TaskDetail['changelogs'][number]): ChangelogItem {
  const user = changelog.created_by
  const summary: ChangelogItem['summary'] = [{ text: `${user.first_name} ${user.last_name} `, bold: true }]
  const mappedChanges: ChangelogItem['summary'][] = Object.entries(changelog.changes)
    .map(([key, change]) => {
      switch (key) {
        case 'created':
          return [{ text: 'created this task' }]
        case 'title':
          return [{ text: 'renamed the task to ' }, { text: `'${change}'`, bold: true }]
        case 'due_date':
          return change !== 'None'
            ? [{ text: 'changed the due date to ' }, { text: formatDate(change as string), bold: true }]
            : [{ text: 'removed the due date' }]
        case 'assignee': {
          const assigneeChange = change as { id?: number, first_name: string, last_name: string }
          return assigneeChange.id
            ? [{ text: 'assigned to ' }, { text: `${assigneeChange.first_name} ${assigneeChange.last_name}`, bold: true }]
            : [{ text: 'removed the assignee' }]
        }
        case 'description':
          return [{ text: 'changed the ' }, { text: 'description', bold: true }]
        case 'subjectivity':
          return [{ text: 'changed the subjectivity to ' }, { text: change ? 'Yes' : 'No', bold: true }]
        case 'restricted_visibility':
          return [{ text: 'changed the visibility to ' }, { text: change ? 'Internal' : 'All Users', bold: true }]
        case 'status':
          return [
            { text: 'moved the task to ' },
            { text: TASK_STATUS_MAP[change as keyof typeof TASK_STATUS_MAP].toLowerCase(), bold: true },
          ]
        default:
          return [{ text: 'made an unknown change' }]
      }
    })
  mappedChanges.forEach((change, i) => {
    if (i > 0) {
      summary.push({ text: i === mappedChanges.length - 1 ? ' and ' : ', ' })
    }
    summary.push(...change)
  })
  summary.push({ text: '.' })

  return {
    user,
    timestamp: changelog.created,
    summary,
  }
}
export function eventEntryResponseToChangelogItem (event: EventEntryResponse): ChangelogItem {
  const { userName, data, created } = event
  const name = userName.split(' ')
  const translationKey = `event.${data?.data.oneofKind}.text`
  const text = i18n.global.t(translationKey)
  return {
    user: {
      id: 0,
      first_name: name[0],
      last_name: name.length > 1 ? name[1] : '',
      email: '',
      active_at: '',
      avatar_url: '',
      isClerk: false,
      is_active: true,
    },
    summary: text === translationKey
      ? []
      : [
          { text: `${userName} `, bold: true },
          { text },
          { text: '.' },
        ],
    timestamp: timestampToString(created, true) ?? '',
  }
}

export function feedEventRouteNameToPolicyRouteName (routeName: SchemaTranslations_FeedEventLink_RouteName): RouteNamesValues | undefined {
  return feedEventRouteNameToPolicyRouteNameMap.get(routeName) ?? undefined
}

export function feedEventRouteNameToApplicationRouteName (routeName: SchemaTranslations_FeedEventLink_RouteName): RouteNamesValues | undefined {
  return feedEventRouteNameToApplicationRouteNameMap.get(routeName) ?? undefined
}

const feedEventRouteNameToPolicyRouteNameMap = new Map<SchemaTranslations_FeedEventLink_RouteName, RouteNamesValues | undefined>([
  [SchemaTranslations_FeedEventLink_RouteName.ROUTE_NAME_UNSPECIFIED, undefined],
  [SchemaTranslations_FeedEventLink_RouteName.ROUTE_NAME_ACTIVITY, 'policyFeed'],
  [SchemaTranslations_FeedEventLink_RouteName.ROUTE_NAME_APPLICATION_REVIEW, 'applicationDetails'],
  [SchemaTranslations_FeedEventLink_RouteName.ROUTE_NAME_ATTACHMENTS, 'policyAttachments'],
  [SchemaTranslations_FeedEventLink_RouteName.ROUTE_NAME_POLICY_DOCS, 'policyPolicyDocs'],
  [SchemaTranslations_FeedEventLink_RouteName.ROUTE_NAME_QUOTE_REVIEW, undefined],
])

const feedEventRouteNameToApplicationRouteNameMap = new Map<SchemaTranslations_FeedEventLink_RouteName, RouteNamesValues | undefined>([
  [SchemaTranslations_FeedEventLink_RouteName.ROUTE_NAME_UNSPECIFIED, undefined],
  [SchemaTranslations_FeedEventLink_RouteName.ROUTE_NAME_ACTIVITY, 'applicationFeed'],
  [SchemaTranslations_FeedEventLink_RouteName.ROUTE_NAME_APPLICATION_REVIEW, 'applicationDetails'],
  [SchemaTranslations_FeedEventLink_RouteName.ROUTE_NAME_ATTACHMENTS, 'applicationAttachments'],
  [SchemaTranslations_FeedEventLink_RouteName.ROUTE_NAME_POLICY_DOCS, 'applicationPolicyDocs'],
  [SchemaTranslations_FeedEventLink_RouteName.ROUTE_NAME_QUOTE_REVIEW, 'applicationReviewQuotes'],
])
