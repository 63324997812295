import { targetGoods } from 'snippets/tymtc/edit'
import { computed } from 'vue'

import { useAuthenticationStore } from '@/stores/authentication'
import { useDiffStore } from '@/stores/diff'

import { extractVehicleChanges } from '@/utils/apdHelpers'
import { createAddressComputedMap, createLocationArrayComputedMap, createArrayComputedMap } from '@/utils/diffHelpers'
import { extractNamedDriverChanges, extractPlateChanges } from '@/utils/mtcHelpers'

import type { PayloadArray } from '@/lib/Payload'
import type { VehicleAppDiff, PlateAppDiff, DriverAppDiff } from '@/stores/diff'
import type { CreateMapFn } from '@/stores/programData'

export const createMap: CreateMapFn = () => {
  return {
    ...createAddressComputedMap('surplusLinesAddress', { path: 'surplusLines', source: 'issue' }),
    ...createAddressComputedMap('surplusLinesAddressRecent', { path: 'surplusLines', source: 'recent' }),
    ...createAddressComputedMap('terminalAddress', { path: 'terminalAddress', source: 'responses' }),
    ...createAddressComputedMap('agencyAddress', { path: 'agencyAddress', source: 'responses' }),
    ...createLocationArrayComputedMap('additionalInsuredArray', 'additionalNamedInsured', 'insuredAddress'),
    ...createLocationArrayComputedMap('terminalLocationsArray', 'terminalLocations'),
    ...createLocationArrayComputedMap('unattachedTerminalLocationsArray', 'unattachedTerminalLocations'),
    ...createArrayComputedMap('targetGoods', 'targetGoods', 'response', targetGoods.reduce<Record<string, string>>((acc, t) => {
      acc[t.path] = t.label
      return acc
    }, {})),
    activeTrucksSchedule: computed<PayloadArray>(() => {
      const diffStore = useDiffStore()
      return extractVehicleChanges((diffStore.appDiffs.vehicleDiff as VehicleAppDiff | null)?.vehicles)
    }),
    inactiveTrucksSchedule: computed<PayloadArray>(() => {
      const diffStore = useDiffStore()
      return extractVehicleChanges((diffStore.appDiffs.vehicleDiff as VehicleAppDiff | null)?.deletedVehicles)
    }),
    activeDriversSchedule: computed<PayloadArray>(() => {
      const diffStore = useDiffStore()
      return extractNamedDriverChanges((diffStore.appDiffs.driverDiff as DriverAppDiff | null)?.drivers)
    }),
    inactiveDriversSchedule: computed<PayloadArray>(() => {
      const diffStore = useDiffStore()
      return extractNamedDriverChanges((diffStore.appDiffs.driverDiff as DriverAppDiff | null)?.deletedDrivers)
    }),
    activePlatesSchedule: computed<PayloadArray>(() => {
      const diffStore = useDiffStore()
      return extractPlateChanges((diffStore.appDiffs.plateDiff as PlateAppDiff | null)?.plates)
    }),
    inactivePlatesSchedule: computed<PayloadArray>(() => {
      const diffStore = useDiffStore()
      return extractPlateChanges((diffStore.appDiffs.plateDiff as PlateAppDiff | null)?.deletedPlates)
    }),
    isFinoAgent: computed<boolean>(() => {
      const authenticationStore = useAuthenticationStore()
      return authenticationStore.agencyMemberships.some((m) => m.agency?.name === 'GAR')
    }),
  }
}
