import type { PiniaPlugin } from 'pinia'

type ApiStore = ReturnType<typeof import('@/stores/api')['useApiStore']>
type ApplicationListState = ReturnType<typeof import('@/stores/applicationList')['useApplicationListStore']>['$state']
type AuthenticationState = ReturnType<typeof import('@/stores/authentication')['useAuthenticationStore']>['$state']
type FilterState = ReturnType<typeof import('@/stores/filter')['useFilterStore']>['$state']
type CmsState = ReturnType<typeof import('@/stores/cms')['useCmsStore']>['$state']
export type PersistedState = {
  api: Pick<ApiStore, 'refreshToken' | 'spaToken' | 'spaTokenExpiration'>
  applicationList: Pick<ApplicationListState, 'rowsPerPage' | 'userColumns'>
  authentication: Pick<AuthenticationState, 'userId' | 'programs' | 'programId'>
  filter: Pick<FilterState, 'filters' | 'page' | 'sortBy' | 'search'>
  cms: Pick<CmsState, 'lastReadTimestamp'>
}

export const VUEX_STORAGE_KEY = 'vuex'
export const PINIA_STORAGE_KEY = 'pinia'

export function createPersistedStatePlugin (): PiniaPlugin {
  // backwards compatibility for state previously stored with vuex
  const vuexState = JSON.parse(localStorage.getItem(VUEX_STORAGE_KEY) || '{}')
  const piniaState = JSON.parse(localStorage.getItem(PINIA_STORAGE_KEY) || '{}')
  const currentState: PersistedState = {
    api: piniaState.api || vuexState.api || {},
    applicationList: piniaState.applicationList || vuexState.application?.list || {},
    authentication: piniaState.authentication || vuexState.authentication || {},
    filter: piniaState.filter || vuexState.filter || {},
    cms: piniaState.cms || vuexState.cms || {},
  }

  // save the combined state so any vuex state isn't lost
  localStorage.setItem(PINIA_STORAGE_KEY, JSON.stringify(currentState))

  return ({ store }) => {
    const id = store.$id as keyof PersistedState
    if (!(id in currentState)) return

    // hydrate if a persisted store is being registered
    const prevState = currentState[id] as Partial<typeof store.$state>
    if (prevState) {
      // migrate any previous state
      switch (id) {
        case 'filter':
          if (typeof prevState.sortBy === 'string') {
            prevState.sortBy = [{ key: prevState.sortBy, order: prevState.sortDesc ? 'desc' : 'asc' }]
          }
      }
      store.$patch(prevState)
    }

    store.$subscribe((event, state) => {
      const storeId = event.storeId as keyof PersistedState
      switch (storeId) {
        case 'api': {
          const apiState = state as ApiStore
          currentState.api = {
            refreshToken: apiState.refreshToken,
            spaToken: apiState.spaToken,
            spaTokenExpiration: apiState.spaTokenExpiration,
          }
          break
        }
        case 'applicationList': {
          const applicationListState = state as ApplicationListState
          currentState.applicationList = {
            rowsPerPage: applicationListState.rowsPerPage,
            userColumns: applicationListState.userColumns,
          }
          break
        }
        case 'authentication': {
          const authenticationState = state as AuthenticationState
          currentState.authentication = {
            userId: authenticationState.userId,
            programs: authenticationState.programs,
            programId: authenticationState.programId,
          }
          break
        }
        case 'cms': {
          const cmsState = state as CmsState
          currentState.cms = { lastReadTimestamp: cmsState.lastReadTimestamp }
          break
        }
        case 'filter': {
          const filterState = state as FilterState
          currentState.filter = {
            filters: filterState.filters,
            page: filterState.page,
            sortBy: filterState.sortBy,
            search: filterState.search,
          }
          break
        }
      }
      localStorage.setItem(PINIA_STORAGE_KEY, JSON.stringify(currentState))
    }, { detached: true })
  }
}
