import { Big } from 'big.js'

import Payload, { PayloadArray } from '@/lib/Payload'
import { formatValue, formatDate } from '@/utils/formatter'

import type { Formatter } from '@policyfly/utils/types/formatter'
import type { VINLookupVehicle } from 'types/api'
import type { DiffVehicle, VehiclesDiff } from 'types/programData/apd'

export type VehicleChangeState = 'Added' | 'Changed' | 'Removed'

const vehicleChanges: { name: string, path: keyof DiffVehicle, formatter: Formatter }[] = [
  { name: 'VIN', path: 'VIN', formatter: 'default' },
  { name: 'Amount', path: 'value', formatter: 'currency' },
  { name: 'Type', path: 'type', formatter: 'default' },
  { name: 'Year', path: 'year', formatter: 'default' },
  { name: 'Make', path: 'make', formatter: 'default' },
  { name: 'Model', path: 'model', formatter: 'default' },
  { name: 'Add Date', path: 'addDate', formatter: 'date' },
  { name: 'Deductible', path: 'deductibleOverride', formatter: 'currency' },
  { name: 'Total Loss', path: 'totalLoss', formatter: 'yN' },
  { name: 'Removal Date', path: 'removalDate', formatter: 'date' },
]

export function summariseVehicle (vehicle: DiffVehicle): string {
  const parts: string[] = []
  parts.push(vehicle.VIN)
  if (vehicle.removalDate) {
    parts.push(`Removed: ${formatDate(vehicle.removalDate, { year: '2-digit' })}`)
  }
  if (vehicle.addDate) {
    parts.push(`Added: ${formatDate(vehicle.addDate, { year: '2-digit' })}`)
  }
  if (Big(vehicle.value || 0).gt(0)) {
    parts.push(formatValue(vehicle.value, 'currency'))
  }
  return parts.join('   ')
}

export function extractVehicleChanges (vehicles: VehiclesDiff | null | undefined): PayloadArray {
  const basePayload = new Payload()
  basePayload.createResponse('state', null)
  basePayload.createResponse('summary', '')
  const payloadArray = new PayloadArray(basePayload)
  if (!vehicles) return payloadArray
  vehicles.added.forEach((vehicle) => {
    const newItem = payloadArray.add()
    newItem.set<VehicleChangeState>('state', 'Added')
    newItem.set('summary', summariseVehicle(vehicle))
  })
  vehicles.changed.forEach((change) => {
    const newSummary = summariseVehicle(change.new)
    const changesList: string[] = []
    vehicleChanges.forEach((c) => {
      const oldVal = formatValue(change.old[c.path], c.formatter) || 'N/A'
      const newVal = formatValue(change.new[c.path], c.formatter) || 'N/A'
      if (oldVal !== newVal) {
        changesList.push(`${c.name}: ${oldVal} to ${newVal}`)
      }
    })
    if (changesList.length > 0) {
      const newItem = payloadArray.add()
      newItem.set<VehicleChangeState>('state', 'Changed')
      newItem.set('summary', `${newSummary}  (${changesList.join(', ')})`)
    }
  })
  if (vehicles.removed) {
    vehicles.removed.forEach((vehicle) => {
      const newItem = payloadArray.add()
      newItem.set<VehicleChangeState>('state', 'Removed')
      newItem.set('summary', summariseVehicle(vehicle))
    })
  }
  return payloadArray
}

export function extractVehicleType (vehicle: VINLookupVehicle, acceptTypes: string[] = ['Truck', 'Tractor', 'Trailer']): string | null {
  const { BodyClass, VehicleType }: VINLookupVehicle = vehicle || {}

  // Check BodyClass for exact matches plus a few variants
  if (BodyClass) {
    if (acceptTypes.includes(BodyClass)) return BodyClass
    if (BodyClass === 'Pickup' && acceptTypes.includes('Truck')) return 'Truck'
    if (BodyClass.includes('Tractor') && acceptTypes.includes('Tractor')) return 'Tractor'
  }

  // Alternative check on VehicleType when proper cased
  if (VehicleType) {
    if (/MPV/i.test(VehicleType)) return 'Truck'
    const parsedVehicleType = VehicleType
      .trim()
      .split('')
      .map((l, i) => i === 0 ? l.toUpperCase() : l.toLowerCase())
      .join('')
    if (acceptTypes.includes(parsedVehicleType)) return parsedVehicleType
  }
  return null
}
