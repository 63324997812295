import { defineStore } from 'pinia'

import { useApiStore } from '@/stores/api'
import { useAppContextStore } from '@/stores/appContext'
import { useAuthenticationStore } from '@/stores/authentication'
import { useFeedStore } from '@/stores/feed'
import { useFormEditStore } from '@/stores/form/edit'
import { useNotificationStore } from '@/stores/notification'
import { usePolicyStore } from '@/stores/policy'
import { useProtobufStore } from '@/stores/protobuf'
import { useSettingsStore } from '@/stores/settings'

import { api } from '@/api'
import router from '@/router'
import routeNames from '@/router/routeNames'
import { hotReloadStore } from '@/utils/build'
import { userJsonToUser } from '@/utils/protobuf'

import type { AxiosErrorCode } from 'types/api'
import type { User, UserlistMembership } from 'types/user'

export interface Wording {
  text: string
  formatted: string
  context: Record<string, string>
  meta: Record<string, string | number | boolean>
}

export const useApplicationStore = defineStore({
  id: 'application',

  state: () => ({
    ready: false,
    assignee: null as User | null,
    owner: null as User | null,
    lock_code: null as number | null,
    wordings: [] as Wording[],
  }),

  getters: {
    assigneeName: (state): string => state.assignee ? `${state.assignee.first_name} ${state.assignee.last_name}` : '',
    sanctionsCheckFailed: (state): boolean => !!state.lock_code,
    issuanceMessage (): string {
      const appContextStore = useAppContextStore()
      return appContextStore.kind === 'RENEWAL'
        ? 'This renewal has been issued.'
        : appContextStore.isCancellation
          ? 'This policy is now cancelled.'
          : 'This policy is now active.'
    },
    PNI (state): string {
      if (!state.ready) return 'Loading...'
      const protobufStore = useProtobufStore()
      const formEditStore = useFormEditStore()
      let PNI: string | null = ''
      let DBA: string | null = ''
      if (protobufStore.currentPolicyState.primaryNamedInsured) {
        PNI = protobufStore.currentPolicyState.primaryNamedInsured.legalName
        DBA = protobufStore.currentPolicyState.primaryNamedInsured.dba
      } else if (formEditStore.PNI) {
        return formEditStore.PNI
      } else {
        const appContextStore = useAppContextStore()
        const nestedResponsePayload = appContextStore.nestedResponsePayload
        if (nestedResponsePayload && nestedResponsePayload.get('PNI')) {
          PNI = nestedResponsePayload.get<string>('PNI')
          DBA = nestedResponsePayload.get<string>('DBA')
        }
      }
      if (!PNI) return ''
      return DBA ? `${PNI} DBA ${DBA}` : PNI
    },
  },

  actions: {
    /**
     * Fetches the application with the provided id and sets relevant state in other stores.
     * If an application fails to load, may redirect based on the reason for failure.
     */
    async fetchApplication (id: number): Promise<void> {
      const notificationStore = useNotificationStore()
      const apiStore = useApiStore()
      const protobufStore = useProtobufStore()
      const appContextStore = useAppContextStore()
      const policyStore = usePolicyStore()
      try {
        const { policyState, policy, json } = await apiStore.application.read(id)
        if (policyState) {
          protobufStore.policyState = policyState
          // @ts-expect-error: Swagger types do not match store
          policyStore.loadPolicy(policy!)
        }
        appContextStore.loadApplicationData(json)
      } catch (err) {
        const errorResponse = (err as AxiosErrorCode).response
        if (errorResponse?.status === 404) {
          notificationStore.setDefaultSnackbar({
            type: 'error',
            msg: 'The application you requested could not be found.',
          })
          router.push({ name: routeNames.HOME })
        } else if (errorResponse?.status === 400 && errorResponse.data?.error?.code === 'PROGRAM_ID_MISMATCH') {
          notificationStore.snackbar = {
            model: true,
            close: true,
            msg: 'That application does not belong to this program.',
            timeout: 0,
            type: 'error',
          }
          router.push({ name: routeNames.HOME })
        } else {
          console.error(err)
        }
      }
    },
    async scan ({ data, file }: { data: unknown, file: Blob }) {
      const authenticationStore = useAuthenticationStore()
      const appContextStore = useAppContextStore()
      const program = authenticationStore.programId
      const formData = new FormData()
      formData.append('program', String(program))
      formData.append('data', JSON.stringify(data))
      formData.append('fileData', file)
      const res = await api.applications.scan({ body: formData })
      const { Application } = res.data
      appContextStore.loadApplicationData(Application)
      this.ready = true
      return Application
    },
    async archive (id: number) {
      const apiStore = useApiStore()
      const appContextStore = useAppContextStore()
      await apiStore.application.archive(id)
      appContextStore.clearApplicationData()
    },
    async delete (id: number) {
      const apiStore = useApiStore()
      const appContextStore = useAppContextStore()
      await apiStore.application.delete(id)
      appContextStore.clearApplicationData()
    },
    async assign (assignee: UserlistMembership['user'] | false) {
      const appContextStore = useAppContextStore()
      const apiStore = useApiStore()
      const protobufStore = useProtobufStore()

      try {
        const id = appContextStore.loadedApplicationData.id!
        const { json, policyState } = await apiStore.application.assign(id, assignee ? userJsonToUser(assignee) : false)

        if (assignee) {
          appContextStore.loadApplicationData(json)
          if (policyState) protobufStore.loadPolicyState(policyState)
        } else {
          this.assignee = null
        }
      } catch (err) {
        console.error(err)
      }
    },
    async refresh (id: number) {
      const apiStore = useApiStore()
      const appContextStore = useAppContextStore()
      const feedStore = useFeedStore()
      const settingsStore = useSettingsStore()

      const modified = await apiStore.application.freshness(id)
      const current = settingsStore.protobuf
        ? Date.parse(feedStore.lastEventCreated)
        : (appContextStore.loadedApplicationData.modified as number)

      if (Date.parse(modified) > current) {
        await this.fetchApplication(id)
        // freshness returns debug events as well, last event may differ from initial application read
        feedStore.lastEventCreated = modified
      }
    },
  },
})

hotReloadStore(useApplicationStore)
