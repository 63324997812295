import { GrpcStatusCode } from '@protobuf-ts/grpcweb-transport'
import { RpcError, UnaryCall } from '@protobuf-ts/runtime-rpc'

import { api } from '@/api'
import { isJWTValid } from '@/utils/api'

import type { CreateInterceptorParams } from '@/stores/api'
import type { RpcInterceptor } from '@protobuf-ts/runtime-rpc'

/**
 * Creates all the interceptors needed for GRPC requests.
 */
export function createRpcInterceptors (params: CreateInterceptorParams): RpcInterceptor[] {
  return [
    createAuthRpcInterceptor(params),
  ]
}

/**
 * Adds the `Authorization` header to GRPC requests.
 * Will use the {@link CreateInterceptorParams.accessToken accessToken} if valid
 * or try and refresh it using the {@link CreateInterceptorParams.refreshToken refreshToken}.
 *
 * See https://github.com/timostamm/protobuf-ts/issues/31#issuecomment-733025632 for how this supports Promises.
 */
export function createAuthRpcInterceptor (params: CreateInterceptorParams): RpcInterceptor {
  async function getToken (): Promise<string> {
    if (isJWTValid(params.accessToken.value)) return params.accessToken.value
    if (!isJWTValid(params.refreshToken.value)) throw new RpcError('Invalid refresh token', GrpcStatusCode[GrpcStatusCode.UNAUTHENTICATED])
    try {
      const res = await api.token.refresh({ body: { refresh: params.refreshToken.value } })
      const token = res.data.access
      params.accessToken.value = token
      return token
    } catch (err) {
      console.error(err)
      throw new RpcError('Failed to get new access token', GrpcStatusCode[GrpcStatusCode.UNAUTHENTICATED])
    }
  }
  async function createInterceptorPromise (...args: Parameters<NonNullable<RpcInterceptor['interceptUnary']>>): Promise<UnaryCall> {
    const [next, method, input, options] = args
    const token = await getToken()
    if (!options.meta) {
      options.meta = {}
    }
    options.meta.Authorization = token
    return next(method, input, options)
  }
  return {
    interceptUnary (next, method, input, options): UnaryCall {
      const interceptorPromise = createInterceptorPromise(next, method, input, options)
      return new UnaryCall(
        method,
        options.meta ?? {},
        input,
        interceptorPromise.then((p) => p.headers),
        interceptorPromise.then((p) => p.response),
        interceptorPromise.then((p) => p.status),
        interceptorPromise.then((p) => p.trailers),
      )
    },
  }
}
