import { AttachmentIDType, AttachmentVisibility } from '@policyfly/protobuf'
import { defineStore } from 'pinia'

import { useNotification } from '@/composables/notification'
import { useApiStore } from '@/stores/api'
import { useAppContextStore } from '@/stores/appContext'
import { useFeedStore } from '@/stores/feed'
import { useSettingsStore } from '@/stores/settings'

import { api } from '@/api'
import router from '@/router'
import routeNames from '@/router/routeNames'
import { hotReloadStore } from '@/utils/build'

import type { AttachmentApiMeta } from './api/endpoints/attachment'
import type { Attachment } from '@policyfly/protobuf'
import type { ApplicationAttachment, SignableDocument, SignableDocuments } from 'types/application'

interface AttachmentState {
  attachments: Attachment[]
  allApplicationAttachments: ApplicationAttachment[]
  allPolicyAttachments: ApplicationAttachment[]
  isListing: boolean
}

export const useAttachmentStore = defineStore({
  id: 'attachment',

  state: (): AttachmentState => ({
    attachments: [],
    allApplicationAttachments: [],
    allPolicyAttachments: [],
    isListing: false,
  }),

  getters: {
    applicationAttachments (): ApplicationAttachment[] {
      return this.allApplicationAttachments.filter((f) => !f.archived)
    },
    policyAttachments (): ApplicationAttachment[] {
      return this.allPolicyAttachments.filter((f) => !f.archived)
    },
    uploadedAttachments (): ApplicationAttachment[] {
      return this.applicationAttachments.filter((a) => !a.policy_document)
    },
    unconfirmedSignatures (): boolean {
      const appContextStore = useAppContextStore()
      if (appContextStore.status === 'TERMINATED') return false
      return this.allApplicationAttachments.some((att) => {
        return !att.archived && !att.soft_archived && att.signature_status === 'SIGNED' && typeof att.signature_confirmed !== 'boolean'
      })
    },
    /**
     * @returns {SignableDocuments | null} Signable Documents Settings along with any signable documents or null
     */
    signableDocuments (): SignableDocuments | null {
      const settingsStore = useSettingsStore()
      const signableDocuments = settingsStore.signableDocuments
      if (!signableDocuments?.documents?.length) return null
      if (!this.allApplicationAttachments) return null
      return {
        ...signableDocuments,
        documents: signableDocuments.documents.reduce<SignableDocument[]>((acc, d) => {
          const { documentType } = d
          const attachment = this.applicationAttachments.find((att) => {
            return !att.archived && !att.soft_archived && att.document_type === documentType
          })
          if (attachment) {
            acc.push({
              attachment,
              settings: d,
            })
          }
          return acc
        }, []),
      }
    },
  },

  actions: {
    async archive ({ id }: { id: number }): Promise<void> {
      const feedStore = useFeedStore()
      const apiStore = useApiStore()

      const { attachment, json, eventLog } = await apiStore.attachment.archive(id)

      this.replaceAttachment(json, attachment)
      // this.archiveFile(id)
      if (eventLog) feedStore.addGlobalEvent(eventLog)
    },
    async list ({ policy }: { policy: boolean } = { policy: false }): Promise<void> {
      const apiStore = useApiStore()
      const appContext = useAppContextStore()
      const { snackbar } = useNotification()

      try {
        this.isListing = true
        const { json, attachments } = policy
          ? await apiStore.attachment.list(appContext.policyId!, AttachmentIDType.ID_Type_Policy)
          : await apiStore.attachment.list(appContext.applicationId!, AttachmentIDType.ID_Type_Application)

        policy
          ? (this.allPolicyAttachments = json)
          : (this.allApplicationAttachments = json)
        // Store protobuf copy of attachments
        if (attachments) this.attachments = attachments
      } catch (error) {
        console.error('Error listing attachments:', error)
        snackbar.error('Error loading attachments')
      } finally {
        this.isListing = false
      }
    },
    /**
     * Loads the attachment with the requested id and replaces any instances in attachments lists.
     *
     * Useful for fully loading the preview data of a lazy loaded attachment.
     */
    async loadIntoList ({ id }: { id: number }): Promise<void> {
      const apiStore = useApiStore()
      const { json, attachment } = await apiStore.attachment.read(id)
      this.replaceAttachment(json, attachment)
    },
    async rename ({ id, name }: { id: number, name: string }): Promise<void> {
      const feedStore = useFeedStore()
      const apiStore = useApiStore()

      const { attachment, json, eventLog } = await apiStore.attachment.rename(id, name)

      this.replaceAttachment(json, attachment)
      if (eventLog) feedStore.addGlobalEvent(eventLog)
    },
    async remove ({ id }: { id: number }): Promise<void> {
      const feedStore = useFeedStore()
      const apiStore = useApiStore()

      const { eventLog } = await apiStore.attachment.delete(id)

      this.removeFile(id)
      if (eventLog) feedStore.addGlobalEvent(eventLog)
    },
    async restore ({ id }: { id: number }): Promise<void> {
      const feedStore = useFeedStore()
      const apiStore = useApiStore()

      const { attachment, json, eventLog } = await apiStore.attachment.restore(id)

      if (json) this.replaceAttachment(json, attachment)
      if (eventLog) feedStore.addGlobalEvent(eventLog)
    },
    async setVisibility ({ id, restricted_visibility }: { id: number, restricted_visibility: boolean }): Promise<void> {
      const feedStore = useFeedStore()
      const apiStore = useApiStore()

      const { attachment, json, eventLog } = await apiStore.attachment.updateVisibility(
        id,
        // eslint-disable-next-line camelcase
        restricted_visibility ? AttachmentVisibility.Visibility_Restricted : AttachmentVisibility.Visibility_Unrestricted)

      if (json) this.replaceAttachment(json, attachment)
      if (eventLog) feedStore.addGlobalEvent(eventLog)
    },
    async softArchive ({ id }: { id: number }): Promise<void> {
      const feedStore = useFeedStore()
      const apiStore = useApiStore()

      const { attachment, json, eventLog } = await apiStore.attachment.archive(id, true)

      if (json) this.replaceAttachment(json, attachment)
      if (eventLog) feedStore.addGlobalEvent(eventLog)
    },
    async upload ({ file, meta, fuzzy }: {
      file: File
      // params from upload endpoint
      meta: AttachmentApiMeta
      fuzzy?: boolean
    }): Promise<void> {
      const apiStore = useApiStore()
      const feedStore = useFeedStore()
      const { attachment, json, eventLog } = await apiStore.attachment.upload(file, meta, fuzzy)

      this.uploadSuccess(json!, attachment)

      if (eventLog) {
        if (router.currentRoute.value.name === routeNames.POLICY_POLICY_DOCS || router.currentRoute.value.name === routeNames.APPLICATION_ATTACHMENTS) {
          feedStore.addGlobalEvent(eventLog)
        } else if (router.currentRoute.value.meta?.policyView) {
          feedStore.addPolicyEvent(eventLog)
        } else {
          feedStore.addApplicationEvent(eventLog)
        }
      }
    },
    confirmSignature ({ luid, confirmed }: { luid: string, confirmed: boolean }): ReturnType<typeof api.attachments.confirm_signature> {
      return api.attachments.confirm_signature({ path: { luid }, body: { confirmed } })
    },
    uploadSuccess (json: ApplicationAttachment, attachment?: Attachment): void {
      this.allApplicationAttachments.unshift(json)
      this.allPolicyAttachments.unshift(json)
      if (attachment) this.attachments.unshift(attachment)
    },
    archiveFile (attachmentId: number): void {
      [this.allApplicationAttachments, this.allPolicyAttachments].forEach((attachments) => {
        const attachment = attachments.find((f) => f.id === attachmentId)
        if (attachment) attachment.archived = true
      })
    },
    removeFile (attachmentId: number): void {
      [this.allApplicationAttachments, this.allPolicyAttachments].forEach((attachments) => {
        const index = attachments.findIndex((f) => f.id === attachmentId)
        if (index !== -1) attachments.splice(index, 1)
      })
      if (this.attachments.length) {
        const index = this.attachments.findIndex((f) => f.metadata?.id === String(attachmentId))
        if (index !== -1) this.attachments.splice(index, 1)
      }
    },
    replaceAttachment (json: ApplicationAttachment, attachment?: Attachment): void {
      [this.allApplicationAttachments, this.allPolicyAttachments].forEach((attachments) => {
        const index = attachments.findIndex((f) => f.id === json.id)
        if (index !== -1) attachments.splice(index, 1, json)
      })
      if (attachment) {
        const index = this.attachments.findIndex((f) => f.metadata?.id === attachment.metadata?.id)
        if (index !== -1) this.attachments.splice(index, 1, attachment)
      }
    },
  },
})

hotReloadStore(useAttachmentStore)
