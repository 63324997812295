import { request } from '../methods'

import type { AxiosResponsePromise } from '../methods'
import type { APIPayloadResponse } from '@policyfly/utils/types'
import type { ProrateMetadata } from 'types/api'
import type { Application, ApplicationAttachment } from 'types/application'

type ApplicationResponse = AxiosResponsePromise<{ Application: Application }>

export default {
  search: request('get', '/api/v1/search/', (params) => {
    if (params.query.search) {
      const searchTerm = params.query.search.replace(/(^\*)|(\*$)/g, '')
      // @ts-expect-error: `__wildcard` is an allowed suffix but isn't present in types
      params.query.primary_named_insured__wildcard = `*${searchTerm}*`.toLocaleLowerCase()
      delete params.query.search
    }
  }),
  // @ts-expect-error: no definition exists because of uncertainty around FormAI
  scan: request('post', '/api/v1/applications/scan/') as
    (opts: { body?: FormData }) => ApplicationResponse,
  update: request('put', '/api/v1/applications/{pk}/'),
  submit: request('put', '/api/v1/applications/{pk}/submit/'),
  read: request('get', '/api/v1/applications/{pk}/'),
  quote: request('put', '/api/v1/applications/{pk}/quote/'),
  selectquote: request('put', '/api/v1/applications/{pk}/selectquote/'),
  to_draft: request('put', '/api/v1/applications/{pk}/to_draft/'),
  // @ts-expect-error: this is a program specific method, we just don't have a way to represent that yet
  to_pending_issue: request('put', '/api/v1/applications/{pk}/to_pending_issue/') as
    (opts: { path: { pk: number }, body?: { data: { responses: APIPayloadResponse[] } }, query: { mode?: 'fuzzy' } }) => ApplicationResponse,
  // @ts-expect-error: this is a program specific method, we just don't have a way to represent that yet
  to_issued: request('put', '/api/v1/applications/{pk}/to_issued/') as
    (opts: { path: { pk: number }, body?: { responses: APIPayloadResponse[] } | FormData, query: { mode?: 'fuzzy' } }) => ApplicationResponse,
  // @ts-expect-error: this is a program specific method, we just don't have a way to represent that yet
  apply_issuance_data: request('put', '/api/v1/applications/{pk}/apply_issuance_data/') as
    (opts: { path: { pk: number }, body: { data: { responses: APIPayloadResponse[] } } | FormData, query: { mode?: 'fuzzy' } }) => ApplicationResponse,
  // @ts-expect-error: this is a program specific method, we just don't have a way to represent that yet
  quality_control: request('put', '/api/v1/applications/{pk}/quality_control/') as
    (opts: { path: { pk: number }, body: { action: string } }) => ApplicationResponse,
  // @ts-expect-error: this is a program specific method, we just don't have a way to represent that yet
  force_quality_recheck: request('put', '/api/v1/applications/{pk}/force_quality_recheck/') as
    (opts: { path: { pk: number } }) => ApplicationResponse,
  // @ts-expect-error: this is a program specific method, we just don't have a way to represent that yet
  authorise: request('put', '/api/v1/applications/{pk}/authorise/') as
    (opts: { path: { pk: number } }) => ApplicationResponse,
  // @ts-expect-error: this is a program specific method, we just don't have a way to represent that yet
  approve: request('put', '/api/v1/applications/{pk}/approve/') as
    (opts: { path: { pk: number } }) => ApplicationResponse,
  // @ts-expect-error: this is a program specific method, we just don't have a way to represent that yet
  finalise: request('put', '/api/v1/applications/{pk}/finalise/') as
    (opts: { path: { pk: number } }) => ApplicationResponse,
  // @ts-expect-error: this is a program specific method, we just don't have a way to represent that yet
  to_cancelled: request('put', '/api/v1/applications/{pk}/to_cancelled/') as
    (opts: { path: { pk: number } }) => ApplicationResponse,
  spawn: request('post', '/api/v1/applications/spawn/'),
  comment: request('post', '/api/v1/applications/{pk}/comment/'),
  upload: request('post', '/api/v1/applications/upload/'),
  decline: request('put', '/api/v1/applications/{pk}/to_declined/'),
  premium: request('put', '/api/v1/applications/{pk}/premium/'),
  preflight_rating_request: request('get', '/api/v1/applications/{pk}/preflight_rating_request/'),
  rate: request('post', '/api/v1/applications/{pk}/rate/'),
  delete: request('delete', '/api/v1/applications/{pk}/'),
  lost: request('put', '/api/v1/applications/{pk}/lost/'),
  // @ts-expect-error: this is a program specific method, we just don't have a way to represent that yet
  sigbypass: request('put', '/api/v1/applications/{pk}/sigbypass/') as
    (opts: { path: { pk: number } }) => ApplicationResponse,
  assign: request('put', '/api/v1/applications/{pk}/assign/'),
  remove_assign: request('delete', '/api/v1/applications/{pk}/assign/'),
  compare: request('get', '/api/v1/applications/{pk}/compare/{target_pk}/'),
  lock: request('put', '/api/v1/applications/{pk}/lock/'),
  // @ts-expect-error: this is a program specific method, we just don't have a way to represent that yet
  license: request('put', '/api/v1/applications/{pk}/license/') as
    (opts: { path: { pk: number }, body: { data: { responses: APIPayloadResponse[] } } }) => ApplicationResponse,
  freshness: request('get', '/api/v1/applications/{pk}/freshness/'),
  // extra metadata is not typed
  prorate: request('post', '/api/v1/applications/{pk}/prorate/') as
    (opts: { path: { pk: number }, body: FormData }) => AxiosResponsePromise<{ ProratableItemSet: string, metadata: ProrateMetadata }>,
  inactivate: request('delete', '/api/v1/applications/{pk}/inactivate/'),
  // @ts-expect-error -- no docs for this yet
  endorsement_meta: request('put', '/api/v1/applications/{pk}/endorsement_meta/') as
    (opts: { path: { pk: number }, body: { data: { responses: APIPayloadResponse[] } } }) => ApplicationResponse,
  // @ts-expect-error: no docs for this
  generate_quote_doc: request('post', '/api/v1/applications/{pk}/generate_quote_doc/') as
    (opts: { path: { pk: number } }) => AxiosResponsePromise<{ Attachment: ApplicationAttachment }>,
}
