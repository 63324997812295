<template>
  <v-card min-width="400px">
    <v-card-text>
      <v-row no-gutters>
        <v-col>
          <Avatar
            :size="24"
            font-class="fs-12"
            :first="user.first_name"
            :last="user.last_name"
            :seed="user.id"
            :avatar-url="user.avatar_url"
          />
          <span class="pl-2 on-surface" v-text="`${user.first_name} ${user.last_name}`" />
        </v-col>
      </v-row>
      <v-row
        no-gutters
        class="text-subheadgrey pl-8"
      >
        <span v-text="`${user.email}`" />
        <div class="flex-grow-1" />
        <v-tooltip
          location="top"
        >
          <template #activator="{ props }">
            <v-btn
              v-bind="props"
              color="primary"
              icon
              variant="text"
              size="x-small"
              class="mt-n2"
              @click.stop="onCopy"
            >
              <v-icon icon="mdi-content-copy" />
            </v-btn>
          </template>
          <span>Copy email address</span>
        </v-tooltip>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import Avatar from '@/components/common/Avatar/Avatar.vue'
import { useClipboard } from '@/composables/clipboard'

import type { User } from 'types/user'
import type { PropType } from 'vue'

export default defineComponent({
  name: 'UserInfoCard',

  components: {
    Avatar,
  },

  props: {
    user: { type: Object as PropType<User>, required: true },
  },

  setup (props) {
    const { copyTextToClipboard } = useClipboard()

    function onCopy (): void {
      copyTextToClipboard({ text: props.user.email, success: 'Email address has been copied.' })
    }

    return {
      onCopy,
    }
  },
})
</script>
