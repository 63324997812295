import get from 'lodash-es/get'
import { computed } from 'vue'

import { useAppContextStore } from '@/stores/appContext'
import { useDiffStore } from '@/stores/diff'

import {
  extractObjectDiffs,
  createAddressComputedMap,
  createArrayComputedMap,
} from '@/utils/diffHelpers'

import type { CreateMapFn } from '@/stores/programData'
import type { TSFixMe } from '@policyfly/types/common'

export function maxSpeedFormatter (maxSpeed: TSFixMe): string {
  if (!maxSpeed) return ''
  const { number = {}, units = {} } = maxSpeed
  return (number.v && units.v) ? `${number.v} ${units.v}` : ''
}

export const createMap: CreateMapFn = () => {
  const charterUseRaw = computed(() => {
    const appContextStore = useAppContextStore()
    const vessel = get(appContextStore, 'nestedResponseObjects.vessel', null)
    if (!vessel) return null
    const { privateUse = {} as { response: unknown }, charterUse = {} as { type: { day: unknown, overnight: unknown }, response: unknown } } = vessel
    const { type = {} as { day: unknown, overnight: unknown } } = charterUse
    return {
      private: { response: privateUse.response || null },
      charter: { response: charterUse.response || null },
      day: type.day || null,
      overnight: type.overnight || null,
    }
  })

  const maxSpeedRaw = computed(() => {
    const appContextStore = useAppContextStore()
    return get(appContextStore, 'nestedResponseObjects.vessel.speed', null)
  })

  return {
    ...createAddressComputedMap('lenderAddress', { path: 'lender', source: 'responses' }),
    ...createAddressComputedMap('inSeasonAddress', { path: 'operators.inSeasonMooring', source: 'responses' }),
    ...createAddressComputedMap('outSeasonAddress', { path: 'operators.outSeasonMooring', source: 'responses' }),
    ...createArrayComputedMap('navigated', 'operators.navigated', 'response', {
      // @ts-expect-error: no longer needed, was 'Moored in West Coast USA/Mexico'
      mooredWest: null,
      outsideBox: 'Outside The Box - 06/01 - 11/31',
      inlandOnly: 'Inland Waters Only (25 Miles or Less)',
      caribbean: 'Caribbean Sea',
      cuba: 'Cuba',
      columbia: 'Columbia',
      haiti: 'Haiti',
      venezuela: 'Venezuela',
      oceania: 'New Zealand/Australia',
      mediterranean: 'Europe/Mediterranean Sea',
      northAtlanticOcean: 'North Atlantic Ocean',
      southAtlanticOcean: 'South Atlantic Ocean',
      northPacificOcean: 'North Pacific Ocean',
      southPacificOcean: 'South Pacific Ocean',
      indianOcean: 'Indian Ocean',
      gulfOfMexico: 'Gulf of Mexico',
    }),
    ...createArrayComputedMap('voyages', 'operators.voyages', 'response', {
      transPacificOneWay: 'Trans Pacific One Way',
      transPacificRound: 'Trans Pacific Round Trip',
      transAtlanticOneWay: 'Trans Atlantic One Way',
      transAtlanticRound: 'Trans Atlantic Round Trip',
      bermudaOneWay: 'Bermuda One Way',
      bermudaRound: 'Bermuda Round Trip',
      panamaOneWay: 'Panama One Way',
      panamaRound: 'Panama Round Trip',
    }),
    ...createArrayComputedMap('charterUse', 'usingOwnGetter', 'response', {
      private: 'Private / Pleasure Use',
      charter: 'Charter',
      day: 'Day Charter',
      overnight: 'Overnight Charter',
    }),
    // overwriting the generated computed with a custom one as the fields don't quite match
    charterUseRaw,
    maxSpeedRaw,
    maxSpeed: computed(() => {
      return { v: maxSpeedFormatter(maxSpeedRaw.value) }
    }),
    maxSpeedDiff: computed(() => {
      const diffStore = useDiffStore()
      return extractObjectDiffs(maxSpeedRaw.value, diffStore.diffMap, maxSpeedFormatter)
    }),
  }
}
