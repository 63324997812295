/* eslint-disable import-x/order */
import './global'
import 'virtual:uno.css'

import { createApp } from 'vue'

import { pinia } from './plugins/pinia'
import { vuetify } from './plugins/vuetify'
import { devtoolsPlugin } from './plugins/devtools/plugin'
import { installSentry } from './plugins/sentry'
import { i18n } from './plugins/i18n'

import App from './App.vue'
import router from './router'
import './api/interceptors'
import { authenticationGuard, programQueryGuard } from './router/middleware'

router.beforeEach(authenticationGuard)
router.beforeEach(programQueryGuard)

const app = createApp(App)

if (import.meta.env.VITE_SENTRY === 'true') {
  installSentry(app)
}

if (import.meta.env.VITE_DEVTOOLS === 'true') {
  app.use(devtoolsPlugin)
}

app.use(router)
app.use(pinia)
app.use(vuetify)
app.use(i18n)
app.mount('#app')
