/**
 * The number of seconds between automatic refreshes on the Home page and Application Feed, Details, Attachments, Policy Documents and Quotes pages.
 */
export const AUTO_REFRESH_INTERVAL = 15

/**
 * Browser confirmation that appears when navigating away from a form using nav or program switcher.
 */
export const FORM_NAVIGATION_WARNING = 'Are you sure you want to navigate away from this form? Your progress will not be saved.'

/**
 * Icons to use for internal/external comments & attachments.
 */
export const VISIBILITY_ICONS = {
  INTERNAL: 'mdi-lock',
  GLOBAL: 'mdi-web',
} as const

/**
 * Used to store working copies of array-like data.
 * Seeing as iteration is only done on the indices of an array, anything stored here is not normally saved or persisted.
 *
 * It's usually best to store working copies under some sort of uuid instead of an array index so array shifting does not de-sync them.
 */
export const WORKING_COPIES_KEY = '$workingCopies'

/**
 * Form sources that are writable to a {@link Payload}.
 */
export const PAYLOAD_WRITABLE_SOURCES = ['local', 'temp'] as const

/**
 * Form sources that are writable to any part of data.
 */
export const WRITABLE_SOURCES = ['local', 'temp', 'pb'] as const

/**
 * A map of task statuses to their displayed status.
 */
export const TASK_STATUS_MAP = {
  OPEN: 'INCOMPLETE',
  PENDING_VERIFICATION: 'PENDING VERIFICATION',
  CLOSED: 'COMPLETE',
} as const
