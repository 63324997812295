import { defineStore } from 'pinia'

import { useAuthenticationStore } from '@/stores/authentication'

import { handleChunkLoadError, hotReloadStore } from '@/utils/build'

import type { EditSchema } from 'types/schema/edit'

export const useFormEditStore = defineStore({
  id: 'formEdit',

  state: () => ({
    sections: [] as EditSchema<string>['sections'],
    sectionsSlug: '',
    headers: {} as NonNullable<EditSchema['headers']>,
    baseResponses: [] as EditSchema['baseResponses'],
    initialForm: {} as EditSchema['initialForm'],
    banner: null as EditSchema['banner'] | null,
    PNI: '',
  }),

  actions: {
    async loadData () {
      const authenticationStore = useAuthenticationStore()
      const slug = authenticationStore.slug
      if (slug === this.sectionsSlug) return Promise.resolve()
      this.$reset()
      try {
        const file = await import(`../../assets/programs/${slug}/edit.ts`)
        const { sections, headers = {}, baseResponses = [], initialForm = {}, banner = null } = file.default || file
        this.sections = sections
        this.headers = headers
        this.sectionsSlug = slug
        this.baseResponses = baseResponses
        this.initialForm = initialForm
        this.banner = banner
      } catch (err) {
        return handleChunkLoadError(err)
      }
    },
  },
})

hotReloadStore(useFormEditStore)
