import { defineStore } from 'pinia'

import { useAuthenticationStore } from '@/stores/authentication'

import Payload from '@/lib/Payload'
import { handleChunkLoadError, hotReloadStore } from '@/utils/build'

import type { FormCoverage } from '@policyfly/schema/types/shared/formSchema'
import type { APIPayloadResponse } from '@policyfly/utils/types'
import type { ProgramSlug } from 'types/program'
import type { IssueSection } from 'types/schema/issue'

export const useFormIssueStore = defineStore({
  id: 'formIssue',

  state: () => ({
    sections: [] as IssueSection<FormCoverage>[],
    sectionsSlug: '' as ProgramSlug | '',
    responses: [] as APIPayloadResponse[],
  }),

  // TS refuses to infer the `state` argument in getters as IssueSection is so complex
  // instead we use `this` and provide a return type
  getters: {
    issuePayload (): Payload | null {
      if (!this.responses || !this.responses.length) return null
      return Payload.fromResponses(this.responses)
    },
  },

  actions: {
    async loadSections (): Promise<IssueSection<FormCoverage>[] | undefined> {
      const authenticationStore = useAuthenticationStore()
      const slug = authenticationStore.slug
      if (this.sectionsSlug === slug) return Promise.resolve(this.sections)
      this.sections = []
      this.sectionsSlug = ''
      try {
        const file = await import(`../../assets/programs/${slug}/issue.ts`)
        const { sections } = file.default || file
        this.sections = sections
        this.sectionsSlug = slug
        return sections
      } catch (err) {
        handleChunkLoadError(err)
      }
    },
  },
})

hotReloadStore(useFormIssueStore)
