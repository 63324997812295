<template>
  <v-dialog
    :key="confirmation.key"
    v-model="dialog"
    :persistent="confirmation.persistent"
    :max-width="confirmation.maxWidth"
    :class="{ 'dialog-overflow': heightOverflow }"
    max-height="none"
  >
    <v-card ref="el" class="overflow-hidden pa-4" :class="{ 'card-with-icon': confirmation.icon }">
      <v-card-title
        v-if="confirmation.title"
        color="#1e3053"
        class="pb-0"
      >
        <span
          class="text-h6 font-weight-medium break-words"
          v-text="confirmation.title"
        />
      </v-card-title>
      <v-avatar
        v-if="confirmation.icon"
        class="confirmation-icon"
        color="primary"
        size="36"
      >
        <v-icon theme="dark" size="18" :icon="confirmation.icon" />
      </v-avatar>
      <component :is="component" @input="setExtraData" @valid="setValid" />

      <v-card-actions v-if="!confirmation.noActions">
        <v-btn
          v-if="confirmation.showClose"
          id="dialog-cancel"
          color="grey"
          variant="text"
          @click="close"
          v-text="closeButton"
        />
        <v-spacer />
        <v-btn
          v-if="!confirmation.onlyConfirm"
          id="dialog-cancel"
          data-test="dialog-cancel"
          color="grey"
          variant="text"
          @click="reject"
          v-text="cancelButton"
        />
        <v-btn
          v-if="!confirmation.onlyCancel"
          id="dialog-confirm"
          data-test="dialog-confirm"
          variant="text"
          color="primary"
          class="text-white"
          :disabled="hasErrors || (confirmation.preventInvalidResolve && !valid)"
          @click="resolve"
          v-text="confirmButton"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { useWindowSize, useElementSize } from '@vueuse/core'
import cloneDeep from 'lodash-es/cloneDeep'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, ref, shallowRef } from 'vue'

import { useNotificationStore } from '@/stores/notification'

import { dialogComponentMap } from './dialogComponentMap'

import type { Confirmation } from '@/stores/notification'
import type { TSFixMe } from '@policyfly/types/common'
import type { VCard } from 'vuetify/components'

export default defineComponent({
  // eslint-disable-next-line vue/no-reserved-component-names
  name: 'Dialog',

  setup () {
    const notificationStore = useNotificationStore()
    const { confirmation } = storeToRefs(notificationStore)
    const { height: windowHeight } = useWindowSize()

    const extra = shallowRef<TSFixMe>({})
    const valid = ref(false)
    const el = ref<VCard>()
    const { height: elHeight } = useElementSize(el)

    const component = computed(() => {
      const componentName = ('component' in confirmation.value && confirmation.value.component) || 'Default'
      return dialogComponentMap[componentName]
    })
    const hasErrors = computed(() => {
      const rules = ('rules' in confirmation.value && confirmation.value.rules) || []
      return rules.some((r) => r(extra.value) !== true)
    })
    const heightOverflow = computed(() => elHeight.value > windowHeight.value)
    const dialog = computed({
      get (): Confirmation['value'] {
        return confirmation.value.value
      },
      set (value: Confirmation['value']) {
        confirmation.value = {
          ...confirmation.value,
          value,
        }
      },
    })
    const closeButton = computed(() => {
      return confirmation.value.closeButton || 'Close'
    })
    const cancelButton = computed(() => {
      return confirmation.value.cancelButton || 'Cancel'
    })
    const confirmButton = computed(() => {
      return confirmation.value.confirmButton || 'Confirm'
    })

    function close (): void {
      dialog.value = false
      extra.value = {}
      notificationStore.clearConfirmationContext()
    }
    function reject (): void {
      dialog.value = false
      extra.value = {}
      confirmation.value.resolve(false)
      notificationStore.clearConfirmationContext()
    }
    function resolve (): void {
      dialog.value = false
      const hasExtra = Object.keys(extra.value).length
      if (hasErrors.value) return

      confirmation.value.resolve(hasExtra ? { extra: extra.value } : true)
      extra.value = {}
    }
    function setExtraData (data: TSFixMe): void {
      extra.value = cloneDeep(data)
    }
    function setValid (validValue: boolean): void {
      valid.value = validValue
    }

    return {
      el,
      confirmation,

      extra,
      valid,
      component,
      hasErrors,
      heightOverflow,
      dialog,
      closeButton,
      cancelButton,
      confirmButton,

      close,
      reject,
      resolve,
      setExtraData,
      setValid,
    }
  },
})
</script>

<style lang="sass" scoped>
.card-with-icon
  padding-left: 54px !important
.confirmation-icon
  position: absolute
  top: 32px
  left: 24px
.v-card-title
  white-space: normal
  padding-left: 24px
  padding-right: 24px
.dialog-overflow
  align-items: start
  overflow: auto
  :deep(.v-overlay__content)
    padding-top: 24px
    padding-bottom: 24px
</style>
