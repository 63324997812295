import { request } from '../methods'

import type { AxiosResponsePromise } from '../methods'

export default {
  // @ts-expect-error: no definition exists
  refresh: request('post', '/api/v1/token/refresh/') as
    (opts: { body: { refresh: string } }) => AxiosResponsePromise<{ access: string }>,
  // @ts-expect-error: no definition exists
  verify: request('post', '/api/v1/token/verify/') as
    (opts: { body: { token: string } }) => AxiosResponsePromise<void>,
}
