import { request } from '../methods'

export default {
  memberships: request('get', '/api/v1/programs/{program_pk}/memberships/'),
  users: request('get', '/api/v1/programs/{program_pk}/memberships/'),
  getUnifiedMemberships: request('get', '/api/v1/programs/{program_pk}/unifiedmemberships/'),
  getMembership: request('get', '/api/v1/programs/{program_pk}/memberships/{pk}/'),
  invite_member: request('post', '/api/v1/programs/{program_pk}/memberships/'),
  update_member: request('put', '/api/v1/programs/{program_pk}/memberships/{pk}/'),
  delete_member: request('delete', '/api/v1/programs/{program_pk}/memberships/{pk}/'),
  // Turns out bordereau is the correct spelling but the api endpoints are misspelled
  bordereau: request('get', '/api/v1/programs/{program_pk}/bordereaux/'),
  create_bordereau: request('post', '/api/v1/programs/{program_pk}/bordereaux/'),
  read_bordereau: request('get', '/api/v1/programs/{program_pk}/bordereaux/{pk}/'),
}
