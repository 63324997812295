import { Big } from 'big.js'
import get from 'lodash-es/get'
import { computed } from 'vue'

import { useAppContextStore } from '@/stores/appContext'
import { useDiffStore } from '@/stores/diff'

import { extractVehicleChanges } from '@/utils/apdHelpers'
import { createArrayComputedMap, createAddressComputedMap, extractObjectDiffs } from '@/utils/diffHelpers'
import { formatValue } from '@/utils/formatter'
import { getNestedResponse, getQuoteResponse } from '@/utils/responses'

import type { PayloadObject, PayloadArray, PayloadResponse } from '@/lib/Payload'
import type { VehicleAppDiff } from '@/stores/diff'
import type { CreateMapFn } from '@/stores/programData'
import type { US_STATES } from '@policyfly/utils/constants'

interface TotalInsuredValue {
  totalTIV: { v?: string }
  nonOwnedTrailersResponse: { v?: string }
  nonOwnedTrailers: { v?: string }
  valuePerUnit: { v?: string }
}
interface Wording {
  name: {
    v: string
  }
}

export function totalInsuredValueFormatter (totalInsuredValue: TotalInsuredValue): string {
  if (!totalInsuredValue) return ''
  const totalTIV = totalInsuredValue.totalTIV.v
  const nonOwnedTrailersResponse = totalInsuredValue.nonOwnedTrailersResponse.v
  const nonOwnedTrailers = totalInsuredValue.nonOwnedTrailers.v || 0
  const valuePerUnit = totalInsuredValue.valuePerUnit.v || '0.00'
  const nounTrailers = +nonOwnedTrailers === 1 ? 'Trailer' : 'Trailers'

  const TIV = formatValue(totalTIV, 'currency')
  if (!nonOwnedTrailersResponse) return TIV
  return `${TIV} (includes ${nonOwnedTrailers} x Non-Owned ${nounTrailers} @ USD ${formatValue(valuePerUnit, 'currency').replace('$', '')})`
}

const STATE_DISCLOSURES: Record<string, string> = {
  AL: 'REF 9022 - Alabama Surplus Lines Notice',
  AK: 'REF 9025A - Alaska Surplus Lines Notice',
  AZ: 'REF 9027A - Arizona Surplus Lines Notice',
  AR: 'REF 9028 - Arkansas Surplus Lines Notice',
  CA: 'REF 9030 - California Surplus Lines Notice',
  CO: 'REF 9031 - Colorado Surplus Lines Notice',
  CT: 'REF 9035A - Connecticut Surplus Lines Notice',
  DE: 'REF 9036 - Delaware Surplus Lines Notice',
  FL: 'REF 9037 - Florida Surplus Lines Notice',
  GA: 'REF 9042 - Georgia Surplus Lines Notice',
  HI: 'REF 9044 - Hawaii Surplus Lines Notice',
  ID: 'REF 9045 - Idaho Surplus Lines Notice',
  IL: 'REF 9046 - Illinois Surplus Lines Notice',
  IA: 'REF 9047 - Iowa Surplus Lines Notice',
  KS: 'REF 9048 - Kansas Surplus Lines Notice',
  KY: 'REF 9092 - Kentucky Surplus Lines Notice',
  LA: 'REF 9049 - Louisiana Surplus Lines Notice',
  ME: 'REF 9050 - Maine Surplus Lines Notice',
  MD: 'REF 9051 - Maryland Surplus Lines Notice',
  MA: 'REF 9054 - Massachusetts Surplus Lines Notice',
  MI: 'REF 9055 - Michigan Surplus Lines Notice',
  MN: 'REF 9056 - Minnesota Surplus Lines Notice',
  MS: 'REF 9057 - Mississippi Surplus Lines Notice',
  MO: 'REF 9058 - Missouri Surplus Lines Notice',
  MT: 'REF 9059 - Montana Surplus Lines Notice',
  NE: 'REF 9060 - Nebraska Surplus Lines Notice',
  NV: 'REF 9061 - Nevada Surplus Lines Notice',
  NH: 'REF 9062 - New Hampshire Surplus Lines Notice',
  NJ: 'REF 9063 - New Jersey Surplus Lines Notice',
  NM: 'REF 9065 - New Mexico Surplus Lines Notice',
  NY: 'REF 9067 - New York Surplus Lines Notice',
  NC: 'REF 9068 - North Carolina Surplus Lines Notice',
  ND: 'REF 9069 - North Dakota Surplus Lines Notice',
  OH: 'REF 9070 - Ohio Surplus Lines Notice',
  OK: 'REF 9071 - Oklahoma Surplus Lines Notice',
  OR: 'REF 9072 - Oregon Surplus Lines Notice',
  PA: 'REF 9073 - Pennsylvania Surplus Lines Notice',
  PR: 'REF 9074 - Puerto Rico Surplus Lines Notice',
  RI: 'REF 9075 - Rhode Island Surplus Lines Notice',
  SC: 'REF 9076A - South Carolina Surplus Lines Notice',
  SD: 'REF 9077 - South Dakota Surplus Lines Notice',
  TN: 'REF 9078 - Tennessee Surplus Lines Notice',
  TX: 'REF 9079 (amended) - Texas Surplus Lines Notice',
  VI: 'REF 9081 - US Virgin Islands Surplus Lines Notice',
  UT: 'REF 9082 - Utah Surplus Lines Notice',
  VT: 'REF 9083 - Vermont Surplus Lines Notice',
  VA: 'REF 9084 - Virginia Surplus Lines Notice',
  WA: 'REF 9085 - Washington Surplus Lines Notice',
  WV: 'REF 9087 - West Virginia Surplus Lines Notice',
  WI: 'REF 9089 - Wisconsin Surplus Lines Notice',
  WY: 'REF 9090 - Wyoming Surplus Lines Notice',
}

const STATE_EXTRA_DISCLOSURES: Record<string, string> = {
  CA: 'REF 9098B - California Surplus Lines Notice (Post-Bind)',
  GA: 'REF 9043 - Georgia Surplus Lines Notice (Standard Disclosure Brochure)',
  TX: 'REF 9080D - Texas Complaints Notice',
}

export const createMap: CreateMapFn = () => {
  const totalInsuredValueRaw = computed<TotalInsuredValue>(() => {
    const appContextStore = useAppContextStore()
    const basePath = 'nestedResponseObjects.'
    return {
      totalTIV: get(appContextStore, `${basePath}coverages.totalTIV`) || {},
      nonOwnedTrailersResponse: get(appContextStore, `${basePath}coverages.nonOwnedTrailers.response`) || {},
      nonOwnedTrailers: get(appContextStore, `${basePath}coverages.nonOwnedTrailers.count`) || {},
      valuePerUnit: get(appContextStore, `${basePath}coverages.nonOwnedTrailers.valuePerUnit`) || {},
    }
  })
  const totalInsuredValue = computed(() => {
    return { v: totalInsuredValueFormatter(totalInsuredValueRaw.value) }
  })
  const hasExcludedDrivers = computed(() => {
    if (getNestedResponse('vehicles.hasExcludedDrivers.response')) return true
    const additionalDrivers = (getQuoteResponse('driversOutsideCriteriaAdditional', true) as PayloadObject[]) || []
    if (additionalDrivers) {
      if (additionalDrivers.some((driver) => !(driver.include as PayloadResponse).get())) return true
    }
    return false
  })
  return {
    ...createAddressComputedMap('physicalAddress', { path: 'physicalAddress', source: 'responses' }),
    ...createAddressComputedMap('agencyAddress', { path: 'agencyAddress', source: 'responses' }),
    ...createArrayComputedMap('commodityType', 'operations.comType', null, {
      agricultural: 'Agricultural',
      autoHaulers: 'Auto Haulers',
      boatHaulers: 'Boat Haulers',
      business: 'Business',
      coalHaulers: 'Coal Haulers',
      contractors: 'Contractors & Construction',
      courier: 'Courier (Packaging Service) / Mail',
      dryGoods: 'Dry Goods',
      dump: 'Dump',
      garbageHaulers: 'Garbage / Refuse Haulers',
      glass: 'Glass',
      householdGoods: 'Household Goods (Movers)',
      intermodal: 'Intermodal / Containers',
      livery: 'Livery',
      livestockHaulers: 'Livestock Haulers',
      logging: 'Logging',
      machinery: 'Machinery & Equipment',
      military: 'Military Equipment',
      milk: 'Milk',
      mobileHome: 'Mobile Home Hauler',
      oversizeOverweight: 'Oversize / Overweight',
      paper: 'Paper / Plastic',
      refrigerated: 'Refrigerated',
      tankerHazmat: 'Tanker (Hazmat)',
      tankerNonHazmat: 'Tanker (Non-Hazmat)',
      other: 'Other',
    }),
    totalInsuredValueRaw,
    totalInsuredValue,
    totalInsuredValueDiff: computed<ReturnType<typeof extractObjectDiffs>>(() => {
      const diffStore = useDiffStore()
      return extractObjectDiffs(
        // @ts-expect-error: Different index signatures
        totalInsuredValueRaw.value,
        diffStore.diffMap,
        totalInsuredValueFormatter,
        undefined,
        true,
      )
    }),
    externalId: computed(() => {
      const appContextStore = useAppContextStore()
      return appContextStore.loadedApplicationData?.external_id ?? null
    }),
    hasExcludedDrivers,
    wordings: computed(() => {
      const wordings: Wording[] = []
      const towingStorageLimit = getNestedResponse('coverages.towingStorage.limit')
      const comGarbage = getNestedResponse('operations.comType.garbageHaulers')
      const nonOwnedTrailers = getNestedResponse('coverages.nonOwnedTrailers.response')
      const quoteState = getQuoteResponse('sld.state') as typeof US_STATES[number]['abbreviation']
      const monthlyReporting = getQuoteResponse('monthlyReporting.response')
      const premium = (getQuoteResponse('proratedPremium') ?? getQuoteResponse('totalPremium') ?? '0.00') as string
      const comboItems = [
        'REF 1191 - Radioactive Contamination Exclusion Clause - Physical Damage - Direct (U.S.A.)',
        'REF 464 - War and Civil War Exclusion Clause',
        'REF 2962 - Biological or Chemical Material Exclusion',
        'REF 2342 - Seepage and/or Pollution and/or Contamination Exclusion',
        'BCM3a - Microorganism Exclusionary (MAP) (Absolute)',
        'NMA 1331 - Cancellation Clause',
        'REF 5019 - Asbestos Endorsement',
        'REF 5021 - Applicable Law (U.S.A.)',
        'REF 3100 - Sanction Limitation and Exclusion Clause',
      ]

      if (STATE_DISCLOSURES[quoteState]) wordings.push({ name: { v: STATE_DISCLOSURES[quoteState] } })
      if (STATE_EXTRA_DISCLOSURES[quoteState]) {
        if (quoteState === 'GA') {
          if (Big(premium).lt(Big('5000.00'))) wordings.push({ name: { v: STATE_EXTRA_DISCLOSURES[quoteState] } })
        } else wordings.push({ name: { v: STATE_EXTRA_DISCLOSURES[quoteState] } })
      }
      wordings.push({ name: { v: 'GLK 1037 AU 11 17 - Certificate of Insurance' } })
      wordings.push({ name: { v: 'GLK 4029 IL 06 09 - Schedule of Forms and Endorsements' } })
      wordings.push({ name: { v: `Protection of Salvage, Recovery and Storage - Limit USD ${formatValue(towingStorageLimit, 'noDecimalCurrency')} - as per Condition 9 of the wording` } })
      wordings.push({ name: { v: 'GLK 2016 AU 01 19 - Auto Physical Damage Insurance Coverage Form' } })
      switch (quoteState) {
        case 'NJ': wordings.push({ name: { v: 'GLK 3001 IL 06 09 - New Jersey Notice to Policyholder' } }); break
        case 'GA': wordings.push({ name: { v: 'GLK 3010 IL 05 11 - Georgia Notice to Policyholder' } }); break
        default: wordings.push({ name: { v: 'GLK 3003 IL 01 17 - Notice to Policyholder' } })
      }
      wordings.push({ name: { v: 'GLK 4118 IL 04 17 - Conformity of Terms Endorsement' } })
      wordings.push({ name: { v: 'GLK 4167 AU 07 16 - Minimum Earned Premium Endorsement' } })
      wordings.push({ name: { v: `GLK 4155 IL 01 19 - Combination Endorsement; inclusive of:\n• ${comboItems.join('\n• ')}` } })
      wordings.push({ name: { v: 'Cancellation Clause (30 days Cancellation, except for non-payment of premium being 10 days), as per wording' } })
      wordings.push({ name: { v: 'GLK 4217 AU 01 19 - Driver Criteria Conditions Endorsement' } })
      wordings.push({ name: { v: 'GLK 5103 01 19 - Policy Provisions Clause' } })
      wordings.push({ name: { v: '5104 06 17 - Complaints and Disputes Clause' } })
      wordings.push({ name: { v: 'GLISE(i)(09.2020) - GLISE Privacy Policy Statement' } })
      wordings.push({ name: { v: 'REF 1998-ALL - Service of Suit Clause (U.S.A.), as per wording (Mendes & Mount and Foley & Lardner)' } })
      wordings.push({ name: { v: 'REF2920 a - Terrorism Exclusion' } })
      wordings.push({ name: { v: 'REF 5219 - U.S. TRIA of 2002 as Amended Not Purchased Clause' } })
      switch (quoteState) {
        case 'WA': wordings.push({ name: { v: 'IL 01 46 08 10 - Washington Common Policy Conditions' } }); break
        default: wordings.push({ name: { v: 'IL 00 17 11 98 - Common Policy Conditions' } })
      }
      wordings.push({ name: { v: 'GLK 2016 AU 01 19 - Automobile Physical Damage Insurance Coverage Form' } })
      wordings.push({ name: { v: 'GLK 4168 AU 07 16 - Pollutant Clean Up and Removal Expense' } })
      wordings.push({ name: { v: 'GLK 4171 AU 09 16 - Towing, Storage and Debris Removal Expense Additional Coverage Endorsement' } })
      if (quoteState === 'FL') {
        wordings.push({ name: { v: 'CP 01 25 02 12 - Florida Changes' } })
        wordings.push({ name: { v: 'GLK 3007 IL 01 15 - Florida Disclosure FL 0909' } })
      }
      if (quoteState === 'LA') {
        wordings.push({ name: { v: 'IL N 048 09 03 - Louisiana Fraud Statement' } })
        wordings.push({ name: { v: 'IL N 145 01 10 (a) - Louisiana Property Disclosure Summary with Wind' } })
        wordings.push({ name: { v: 'IL N 145 01 10 (b) - Louisiana Property Disclosure Summary without Wind' } })
      }
      if (quoteState === 'CA') wordings.push({ name: { v: 'GLK 3005 IL 07 11 D2 - California D2' } })
      wordings.push({ name: { v: 'BC-TR1 - Claim Notification Clause (U.S.A.) - Insurers to appoint Adjusters' } })
      wordings.push({ name: { v: 'REF 9151 - Data Protection Short Form Information Notice' } })
      wordings.push({ name: { v: 'REF 5401 - Property Cyber and Data Exclusion' } })
      wordings.push({ name: { v: 'Insuring Agreement 2 - Basis of Valuation: Actual Cash Value' } })
      wordings.push({ name: { v: 'Loss Payee(s) are automatically added hereon as their interest may appear' } })
      wordings.push({ name: { v: 'Salvage Clause, as per wording' } })
      wordings.push({ name: { v: 'Total or Constructive Total Loss, as per wording' } })
      if (comGarbage) {
        wordings.push({ name: { v: 'REF 542 - Garbage Refuse Warranty' } })
        wordings.push({ name: { v: 'BC-TR5 - Hot Ash Toxic Waste Inclusion' } })
      }
      if (monthlyReporting) wordings.push({ name: { v: 'Monthly Reporting / Adjustment Clause' } })
      if (hasExcludedDrivers.value) wordings.push({ name: { v: 'BC-TR3 - Named Driver Exclusion' } })
      if (nonOwnedTrailers) wordings.push({ name: { v: 'CRA214 - Non-Owned Trailer Endorsement' } })
      wordings.push({ name: { v: 'BC-TR2 - Trailer Interchange Endorsement' } })

      return wordings
    }),
    activeVehicleSchedule: computed<PayloadArray>(() => {
      const diffStore = useDiffStore()
      return extractVehicleChanges((diffStore.appDiffs.vehicleDiff as VehicleAppDiff | null)?.vehicles)
    }),
    inactiveVehicleSchedule: computed<PayloadArray>(() => {
      const diffStore = useDiffStore()
      return extractVehicleChanges((diffStore.appDiffs.vehicleDiff as VehicleAppDiff | null)?.deletedVehicles)
    }),
  }
}
