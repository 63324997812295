<template>
  <v-select
    v-if="prettyPrograms.length > 1"
    id="program-switcher"
    v-model="selectedProgram"
    class="py-2 mr-4"
    density="compact"
    :items="prettyPrograms"
    item-title="text"
    style="width: 300px"
    :menu-props="{ maxHeight: 600, contentClass: 'program-menu' }"
    variant="outlined"
    hide-details
  />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useNotification } from '@/composables/notification'
import { useAppContextStore } from '@/stores/appContext'
import { useAuthenticationStore } from '@/stores/authentication'
import { useProgramDataStore } from '@/stores/programData'
import { useSettingsStore } from '@/stores/settings'

import { FORM_NAVIGATION_WARNING } from '@/constants'
import routeNames from '@/router/routeNames'

export default defineComponent({
  name: 'ProgramSwitcher',

  setup () {
    const { userConfirm } = useNotification()

    const authenticationStore = useAuthenticationStore()
    const prettyPrograms = computed<{ text: string, value: number }[]>(() => {
      return authenticationStore.programs.map((item) => ({
        text: `${item.program.client} - ${item.program.name}`,
        value: item.program.id,
      }))
    })
    const selectedProgram = computed({
      get (): number {
        return authenticationStore.currentProgram!.program.id
      },
      set (id: number) {
        selectProgram(id)
      },
    })

    const route = useRoute()
    const router = useRouter()
    const programDataStore = useProgramDataStore()
    const appContextStore = useAppContextStore()
    const settingsStore = useSettingsStore()
    async function selectProgram (id: number): Promise<void> {
      if (id === authenticationStore.programId) return
      if (route.meta?.form && !await userConfirm({ title: FORM_NAVIGATION_WARNING })) return
      programDataStore.resetProgramData()
      authenticationStore.programId = id
      appContextStore.clearApplicationData()
      await settingsStore.load()
      await router.replace({ name: routeNames.HOME })
    }

    return {
      prettyPrograms,
      selectedProgram,
    }
  },
})
</script>

<style lang="sass" scoped>
.program-switcher-activator
  border-bottom: 1px solid white
  span
    line-height: 24px
  .icon
    margin-left: 20px
.tile-title
  padding: 0px 79px 0px 0px
</style>
